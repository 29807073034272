import { Col } from 'antd';
import styled from '@emotion/styled';

export const ContentContainer = styled.div`
  width: 100%;
  margin: 0 auto;

  * {
    color: #000 !important;
  }

  @media (min-width: 768px) {
    width: 768px;
  }

  @media print {
    page-break-after: always;

    &:last-child {
      page-break-after: auto;
    }
  }
`;

export const InvoiceContentContainer = styled.div`
  padding: 0 8px;
`;

export const OrderInfoTitleText = styled.p`
  margin-bottom: 0px;
  padding: 0 16px;
`;

export const PriceBreakdownContainer = styled.div`
  text-align: left;
  border-bottom: 1px dashed #000000;
  border-top: 1px dashed #000000;
  padding: 8px 16px;
`;

export const PriceBreakdownItem = styled(Col)`
  text-align: right;
`;
