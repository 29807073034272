import { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import {
  CommentOutlined,
  DiffOutlined,
  FileTextOutlined,
  TagsOutlined,
  ReloadOutlined,
  WarningFilled,
  PicRightOutlined,
  TrophyOutlined,
  DisconnectOutlined,
  ExclamationCircleOutlined,
  RightOutlined,
  CalculatorOutlined
} from '@ant-design/icons';
import { Alert, Avatar, Popconfirm, Row, Col, Table, Tooltip, Modal, notification } from 'antd';
import { useTranslation } from 'react-i18next';

import { REACT_APP_LIVE_STREAM_WS_URL } from 'config/env';

import { useGetBlacklistedSocialMediaUserIds, patchSocialMediaUserBlacklistStatus } from 'apis/socialMediaUser';
import { useGetPaginatedPostComments, patchUpdatePostHighTraffic } from 'apis/post';

import { guard } from 'utils/general';
import { DEFAULT_PAGINATION_QUERY } from 'utils/constants';
import { constructColumn, constructColumnFilterSearch, handleOnAggregationTableChange } from 'utils/table/table';
import { logInfo, logInfoPure } from 'utils/logging';
import { getSubscriptionPlanRoute } from 'utils/routes';

import FullWidthContainer from 'components/FullWidthContainer/FullWidthContainer';
import TextButton from 'components/TextButton/TextButton';
import TicketIcon from 'components/Icon/TicketIcon';
import Title from 'components/Title/Title';
import CustomerTier from 'components/CustomerTier/CustomerTier';

import AddOrderModal from '../AddOrderModal/AddOrderModal';
import {
  CommentContainer,
  CommentIconContainer,
  NameContainer,
  NameLabel,
  StyledCheckbox,
  StyledRestreamCommentsButtonContainer,
  StyledRestreamCommentsButton,
  StyledRestreamCommentsButtonCount,
  TitleContainerRow,
  StyledTooltip
} from './FbLiveComments.styles';

const HIGH_TRAFFIC_NUM_OF_COMMENTS_THRESHOLD = 150;

const WS_EVENTS = { NEW_COMMENTS: 'newComments', CONNECTION_SUCCESSFUL: 'connectPostSubsSuccess' };
const WS_CLOSE_CODES = { NORMAL: 1000, GOING_AWAY: 1001 };

const SYSTEM_REMARK_CODE_MAPPING = {
  '-2': {
    titleKey: 'pageOverview:comment-system-remark-title-remark',
    messageKey: 'pageOverview:comment-system-remark-unknown-error',
    DisplayIconComp: ExclamationCircleOutlined
  },
  100: {
    1: {
      titleKey: 'pageOverview:comment-system-remark-title-remark',
      messageKey: 'pageOverview:comment-system-remark-comment-issue-repeat',
      DisplayIconComp: DiffOutlined
    },
    2: {
      titleKey: 'pageOverview:comment-system-remark-title-remark',
      messageKey: 'pageOverview:comment-system-remark-comment-issue-pattern-mismatch',
      DisplayIconComp: ExclamationCircleOutlined
    }
  },
  101: {
    1: {
      titleKey: 'pageOverview:comment-system-remark-title-remark',
      messageKey: 'pageOverview:comment-system-remark-product-setting-keyword-not-found',
      DisplayIconComp: ExclamationCircleOutlined
    },
    2: {
      titleKey: 'pageOverview:comment-system-remark-title-remark',
      messageKey: 'pageOverview:comment-system-remark-product-setting-insufficient-inventory',
      DisplayIconComp: ExclamationCircleOutlined
    },
    3: {
      titleKey: 'pageOverview:comment-system-remark-title-remark',
      messageKey: 'pageOverview:comment-system-remark-product-setting-sold-out',
      DisplayIconComp: ExclamationCircleOutlined
    },
    4: {
      titleKey: 'pageOverview:comment-system-remark-title-remark',
      messageKey: 'pageOverview:comment-system-remark-product-setting-min-purchase-limit-not-reach',
      DisplayIconComp: ExclamationCircleOutlined
    },
    5: {
      titleKey: 'pageOverview:comment-system-remark-title-remark',
      messageKey: 'pageOverview:comment-system-remark-product-setting-max-purchase-limit-reach',
      DisplayIconComp: ExclamationCircleOutlined
    }
  },
  102: {
    1: {
      titleKey: 'pageOverview:comment-system-remark-title-remark',
      messageKey: 'pageOverview:comment-system-remark-user-setting-user-blacklist',
      DisplayIconComp: ExclamationCircleOutlined
    }
  },
  103: {
    1: {
      titleKey: 'pageOverview:comment-system-remark-title-remark',
      messageKey: 'pageOverview:comment-system-remark-product-setting-quantity-change',
      DisplayIconComp: CalculatorOutlined
    }
  },
  104: {
    1: {
      titleKey: 'pageOverview:comment-system-remark-title-remark',
      messageKey: 'pageOverview:comment-system-remark-store-setting-disabled-reduce-purchase-quantity',
      DisplayIconComp: ExclamationCircleOutlined
    }
  },
  200: {
    1: {
      titleKey: 'pageOverview:comment-system-remark-title-remark',
      messageKey: 'pageOverview:comment-system-remark-sm-issue-incomplete-info',
      DisplayIconComp: ExclamationCircleOutlined
    }
  },
  201: {
    '-2': {
      titleKey: 'pageOverview:comment-system-remark-title-unable-to-send-link',
      messageKey: 'pageOverview:comment-system-remark-sm-fb-unknown-error',
      DisplayIconComp: DisconnectOutlined
    },
    '-1': {
      titleKey: 'pageOverview:comment-system-remark-title-unable-to-send-link',
      messageKey: 'pageOverview:comment-system-remark-sm-fb-unexpected-error',
      DisplayIconComp: DisconnectOutlined
    },
    10: {
      titleKey: 'pageOverview:comment-system-remark-title-unable-to-send-link',
      messageKey: 'pageOverview:comment-system-remark-sm-fb-thread-controlled',
      DisplayIconComp: DisconnectOutlined
    },
    190: {
      titleKey: 'pageOverview:comment-system-remark-title-unable-to-send-link',
      messageKey: 'pageOverview:comment-system-remark-sm-fb-access-token',
      DisplayIconComp: DisconnectOutlined
    },
    100: {
      titleKey: 'pageOverview:comment-system-remark-title-unable-to-send-link',
      messageKey: 'pageOverview:comment-system-remark-sm-fb-invalid-param',
      DisplayIconComp: DisconnectOutlined
    },
    105: {
      titleKey: 'pageOverview:comment-system-remark-title-unable-to-send-link',
      messageKey: 'pageOverview:comment-system-remark-sm-fb-exceed-max-param',
      DisplayIconComp: DisconnectOutlined
    },
    200: {
      titleKey: 'pageOverview:comment-system-remark-title-unable-to-send-link',
      messageKey: 'pageOverview:comment-system-remark-sm-fb-permission',
      DisplayIconComp: DisconnectOutlined
    },
    551: {
      titleKey: 'pageOverview:comment-system-remark-title-unable-to-send-link',
      messageKey: 'pageOverview:comment-system-remark-sm-fb-person-unavailable',
      DisplayIconComp: DisconnectOutlined
    },
    613: {
      titleKey: 'pageOverview:comment-system-remark-title-unable-to-send-link',
      messageKey: 'pageOverview:comment-system-remark-sm-fb-api-rate-limit',
      DisplayIconComp: DisconnectOutlined
    },
    2022: {
      titleKey: 'pageOverview:comment-system-remark-title-unable-to-send-link',
      messageKey: 'pageOverview:comment-system-remark-sm-fb-app-temp-disabled',
      DisplayIconComp: DisconnectOutlined
    },
    10900: {
      titleKey: 'pageOverview:comment-system-remark-title-unable-to-send-link',
      messageKey: 'pageOverview:comment-system-remark-sm-fb-activity-replied',
      DisplayIconComp: DisconnectOutlined
    },
    10901: {
      titleKey: 'pageOverview:comment-system-remark-title-unable-to-send-link',
      messageKey: 'pageOverview:comment-system-remark-sm-fb-activity-reply-time-expired',
      DisplayIconComp: DisconnectOutlined
    },
    10903: {
      titleKey: 'pageOverview:comment-system-remark-title-unable-to-send-link',
      messageKey: 'pageOverview:comment-system-remark-sm-fb-user-cant-reply',
      DisplayIconComp: DisconnectOutlined
    },
    10904: {
      titleKey: 'pageOverview:comment-system-remark-title-unable-to-send-link',
      messageKey: 'pageOverview:comment-system-remark-sm-fb-page-message-disabled',
      DisplayIconComp: DisconnectOutlined
    }
  },
  300: {
    1: {
      titleKey: 'pageOverview:comment-system-remark-title-remark',
      messageKey: 'pageOverview:comment-system-remark-system-setting-order-limit-reached',
      DisplayIconComp: ExclamationCircleOutlined
    }
  }
};

class SystemRemark {
  constructor(code, subcode, metadata) {
    this.code = code;
    this.subcode = subcode;
    this.metadata = metadata;

    this.systemRemarkInfo = guard(
      () => (this.subcode ? SYSTEM_REMARK_CODE_MAPPING[this.code][this.subcode] : SYSTEM_REMARK_CODE_MAPPING[this.code]),
      SYSTEM_REMARK_CODE_MAPPING['-2']
    );
  }

  getDisplayIconComp() {
    return this.systemRemarkInfo.DisplayIconComp;
  }

  formatMessage(t) {
    const quantityChanged = guard(() => this.metadata.quantityChanged);

    return t(this.systemRemarkInfo.messageKey, {
      code: this.code,
      subcode: this.subcode,
      productKeyword: guard(() => this.metadata.productKeyword),
      ...(quantityChanged && {
        isIncreased: quantityChanged > 0 ? 1 : 0,
        quantityChanged: Math.abs(quantityChanged)
      })
    });
  }

  formatTitle(t) {
    return t(this.systemRemarkInfo.titleKey);
  }
}

const formatComments = (comments, blacklist) =>
  comments.map(comment => ({ ...comment, isUserBlacklisted: blacklist && blacklist.includes(comment.sourceUserId) }));

const TableAlertInfo = ({ t, systemRemarks }) => {
  const hasMultiRemarks = systemRemarks.length > 1;
  const firstSystemRemarkObj = new SystemRemark(systemRemarks[0].code, systemRemarks[0].subcode, systemRemarks[0].metadata);

  const DisplayIconComp = hasMultiRemarks ? ExclamationCircleOutlined : firstSystemRemarkObj.getDisplayIconComp();

  return (
    <CommentIconContainer
      canClick
      onClick={() => {
        Modal.info({
          title: <b>{hasMultiRemarks ? t('pageOverview:comment-system-remark-title-remarks') : firstSystemRemarkObj.formatTitle(t)}</b>,
          content: systemRemarks.map((systemRemark, index) => {
            const systemRemarkObj = new SystemRemark(systemRemark.code, systemRemark.subcode, systemRemark.metadata);

            return (
              <p key={index} style={{ marginBottom: '4px' }}>
                {systemRemarkObj.formatMessage(t)}
              </p>
            );
          })
        });
      }}
    >
      <DisplayIconComp style={{ color: '#ff7212' }} />
    </CommentIconContainer>
  );
};

const CommentItemIcon = ({ t, comment }) => {
  if (comment.orderId || comment.luckyDrawId || comment.customerPromoId) {
    return (
      <>
        {comment.orderId && (
          <CommentIconContainer>
            <TagsOutlined style={{ color: '#ff7212' }} />
          </CommentIconContainer>
        )}
        {comment.luckyDrawId && (
          <CommentIconContainer>
            {comment.isWinner ? <TrophyOutlined style={{ color: '#ff7212' }} /> : <TicketIcon width={20} height={20} />}
          </CommentIconContainer>
        )}
        {comment.customerPromoId && (
          <CommentIconContainer>
            <PicRightOutlined style={{ color: '#ff7212' }} />
          </CommentIconContainer>
        )}
        {comment.systemRemarks && <TableAlertInfo t={t} systemRemarks={comment.systemRemarks} />}
      </>
    );
  } else if (comment.systemRemarks) {
    return <TableAlertInfo t={t} systemRemarks={comment.systemRemarks} />;
  }

  return null;
};

const onWSNewMessage = onNewComments => event => {
  logInfo('Message event received from WebSocket');
  logInfoPure(event);

  const data = JSON.parse(event.data);

  if (data.event === WS_EVENTS.NEW_COMMENTS) {
    onNewComments(data.comments);
  }
};

const connectToStreamService = (sourceLivePostId, onNewComments, onWebsocketClose = () => {}) => {
  const WebSocketClient = new WebSocket(REACT_APP_LIVE_STREAM_WS_URL);

  WebSocketClient.onmessage = onWSNewMessage(onNewComments);

  WebSocketClient.onerror = event => {
    logInfo('on error received from WebSocket');
    logInfoPure(event);
  };

  WebSocketClient.onclose = event => {
    logInfo('on close received from WebSocket');
    logInfoPure(event);
    onWebsocketClose(event);
  };

  WebSocketClient.onopen = event => {
    logInfo('on open received from WebSocket');
    logInfoPure(event);
    WebSocketClient.send(JSON.stringify({ action: 'subscribeToPost', postId: sourceLivePostId }));
  };

  return WebSocketClient;
};

const constructColumns = ({ t, source, handleOnCreateOrderClick, handleOnBlacklistModalConfirm, onViewOrderClick }) => [
  {
    ...constructColumn(t('pageOverview:live-comment-table-header-username'), 'name', { width: '25%', emptyDisplayValue: 'Unknown User Name' }),
    render: (text, record) => {
      const title = !record.isUserBlacklisted
        ? t('pageOverview:live-comment-blacklist-title-blacklist')
        : t('pageOverview:live-comment-blacklist-title-unblacklist');
      const okText = !record.isUserBlacklisted
        ? t('pageOverview:live-comment-blacklist-button-blacklist')
        : t('pageOverview:live-comment-blacklist-button-unblacklist');
      const cancelText = t('common:modal-cancel-text');

      return (
        <Popconfirm
          title={title}
          okText={okText}
          cancelText={cancelText}
          onConfirm={handleOnBlacklistModalConfirm(record.sourceUserId, source, !record.isUserBlacklisted)}
        >
          <NameContainer>
            <StyledTooltip title={record.isUserBlacklisted ? t('pageOverview:live-comment-blacklist-tooltip') : undefined} zIndex="1055">
              <Row>
                {!!record.storeCustomer?.tier?.label && (
                  <Col span={24}>
                    <CustomerTier
                      key={record.storeCustomer.tier.label}
                      emoji={record.storeCustomer.tier.emoji}
                      label={record.storeCustomer.tier.label}
                    />
                  </Col>
                )}
                <Col span={24}>
                  {record.userProfilePicUrl && (
                    <>
                      <Avatar size="small" src={`${record.userProfilePicUrl}`} />{' '}
                    </>
                  )}
                  <NameLabel isUserBlacklisted={record.isUserBlacklisted}>{text}</NameLabel>
                </Col>
              </Row>
            </StyledTooltip>
          </NameContainer>
        </Popconfirm>
      );
    },
    ...constructColumnFilterSearch('name', t('pageOverview:live-comment-table-header-search-username'), { hasAggregationFilter: true })
  },
  {
    ...constructColumn(t('pageOverview:live-comment-table-header-comments'), 'message', { width: '45%' }),
    render: (text, record) => {
      return (
        <CommentContainer>
          <CommentItemIcon t={t} comment={record} />
          {text}
        </CommentContainer>
      );
    },
    ...constructColumnFilterSearch('message', t('pageOverview:live-comment-table-header-search-comments'), { hasAggregationFilter: true })
  },
  {
    ...constructColumn(t('pageOverview:live-comment-table-header-actions'), 'action', { width: '30%' }),
    render: (text, record) => {
      const hasOrder = record.orderId;
      return (
        <Row>
          {!hasOrder ? (
            <TextButton
              icon={<TagsOutlined />}
              onClick={() => handleOnCreateOrderClick(record)}
              text={t('pageOverview:live-comment-table-action-create-order-button')}
              tooltipMessage={t('common:text-button-tooltip-message', {
                action: t('pageOverview:live-comment-table-action-tooltip-message-create-order')
              })}
              disabled={!record.sourceUserId}
            />
          ) : (
            <TextButton
              color="highlight"
              icon={<FileTextOutlined />}
              onClick={() => onViewOrderClick(record.orderId)}
              text={t('pageOverview:live-comment-table-action-view-order-button')}
              tooltipMessage={t('common:text-button-tooltip-message', {
                action: t('pageOverview:live-comment-table-action-tooltip-message-view-order')
              })}
            />
          )}
        </Row>
      );
    }
  }
];

const constructSourceAndPostId = (source, postId) => {
  return `${source}_${postId}`;
};

const useFbLiveStream = (source, sourcePostId, { shouldConnectToStream, onNewComments }) => {
  const webSocketClient = useRef();
  const [isInit, setIsInit] = useState(false);

  useEffect(() => {
    if (shouldConnectToStream && !isInit && source && sourcePostId) {
      const sourceAndPostId = constructSourceAndPostId(source, sourcePostId);

      setIsInit(true);

      const onWebsocketClose = event => {
        if (event.code !== WS_CLOSE_CODES.NORMAL) {
          // Websocket might get close unexpectedly due to idle or 2hours max connection time
          // Note: https://docs.aws.amazon.com/apigateway/latest/developerguide/limits.html#apigateway-execution-service-websocket-limits-table
          logInfo('Abnormal connection close, could be because of timeout or some random errors');
          logInfo('Reconnecting...');

          webSocketClient.current = connectToStreamService(sourceAndPostId, onNewComments, onWebsocketClose);
        }
      };

      webSocketClient.current = connectToStreamService(sourceAndPostId, onNewComments, onWebsocketClose);
    }
  }, [shouldConnectToStream, isInit, source, sourcePostId, onNewComments]);

  useEffect(() => {
    if (shouldConnectToStream && isInit) {
      // Refresh new message function handler
      webSocketClient.current.onmessage = onWSNewMessage(onNewComments);
    }
  }, [shouldConnectToStream, isInit, onNewComments]);

  useEffect(() => {
    return () => {
      if (shouldConnectToStream && webSocketClient.current) {
        webSocketClient.current.close(WS_CLOSE_CODES.NORMAL);
      }
    };
  }, [shouldConnectToStream]);
};

const useComments = ({ postId, shouldShowMoreComments, isLoadingBlacklist, blacklist }) => {
  const defaultPageSize = shouldShowMoreComments ? 100 : 50;

  const [comments, setComments] = useState([]);
  const [query, setQuery] = useState({ ...DEFAULT_PAGINATION_QUERY, limit: defaultPageSize });
  const [totalCount, setTotalCount] = useState(0);
  const [isNeedToResetComments, setIsNeedToResetComments] = useState(true);

  const { isLoading: isPostCommentsLoading, paginatedData: postComments, total, refetch: refetchPostComments } = useGetPaginatedPostComments(
    postId,
    query
  );

  useEffect(() => {
    // For init and change page
    if (isNeedToResetComments && !isPostCommentsLoading && postComments && !isLoadingBlacklist) {
      const initComments = formatComments(postComments, blacklist);

      setComments(initComments);
      setTotalCount(total);
      setIsNeedToResetComments(false);
    }
  }, [isNeedToResetComments, isPostCommentsLoading, postComments, isLoadingBlacklist, blacklist, total]);

  useEffect(() => {
    // For when blacklist change
    if (!isLoadingBlacklist && blacklist) {
      setComments(prevComments => {
        return formatComments(prevComments, blacklist);
      });
    }
  }, [isLoadingBlacklist, blacklist]);

  const updateCurrentPage = useCallback(newPage => {
    setQuery(prevQuery => {
      return { ...prevQuery, currentPage: newPage };
    });
  }, []);

  const updateQuery = useCallback(newQuery => {
    setQuery(newQuery);
    setIsNeedToResetComments(true);
  }, []);

  const resetQuery = useCallback(() => {
    setQuery({ ...DEFAULT_PAGINATION_QUERY, limit: defaultPageSize });
    setIsNeedToResetComments(true);
  }, [defaultPageSize]);

  const addNewComments = useCallback(
    newComments => {
      setComments(prevComments => {
        const formattedNewComments = formatComments(newComments, blacklist);

        setTotalCount(prevTotalCount => {
          return prevTotalCount + formattedNewComments.length;
        });

        return [...formattedNewComments, ...prevComments].slice(0, query.limit);
      });
    },
    [query.limit, blacklist]
  );

  const updateCommentWithNewOrderId = useCallback((commentId, newOrderId) => {
    setComments(prevComments => {
      const newComments = [...prevComments];

      const index = newComments.findIndex(comment => {
        return comment.commentId === commentId;
      });

      newComments[index] = {
        ...newComments[index],
        orderId: newOrderId
      };

      return newComments;
    });
  }, []);

  const reloadComments = useCallback(() => {
    refetchPostComments();
    setIsNeedToResetComments(true);
  }, [refetchPostComments]);

  return {
    comments,
    isCommentsLoading: isPostCommentsLoading,
    defaultPageSize,
    totalCommentCount: totalCount,

    addNewComments,
    updateCommentWithNewOrderId,
    reloadComments,

    currentPage: query.currentPage,
    updateCurrentPage,

    updateQuery,
    resetQuery
  };
};

const useInterval = ({ timeInSec, shouldStart = false, shouldEnableCountdown = false, onIntervalEnd }) => {
  const [hasManualTriggered, setHasManualTriggered] = useState(false);
  const [countdownTimerInSec, setCountdownTimerInSec] = useState();

  useEffect(() => {
    if (shouldEnableCountdown) {
      setCountdownTimerInSec(timeInSec);
    }
  }, [timeInSec, shouldEnableCountdown, shouldStart]); // listen to shouldStart for reset countdown

  useEffect(() => {
    if (shouldStart && !hasManualTriggered) {
      const interval = setInterval(() => {
        onIntervalEnd();
        shouldEnableCountdown &&
          setCountdownTimerInSec(prevTimer => {
            if (prevTimer <= 1) {
              return prevTimer;
            }
            return timeInSec;
          });
      }, timeInSec * 1000);

      const countdownTimerInterval = shouldEnableCountdown
        ? setInterval(() => {
            setCountdownTimerInSec(prevTimer => {
              if (prevTimer > 1) {
                return prevTimer - 1;
              }
              return timeInSec;
            });
          }, 1000)
        : undefined;

      return () => {
        clearInterval(interval);
        shouldEnableCountdown && clearInterval(countdownTimerInterval);
      };
    } else {
      setHasManualTriggered(false);
    }
  }, [timeInSec, onIntervalEnd, shouldStart, shouldEnableCountdown, hasManualTriggered]);

  const trigger = useCallback(() => {
    setHasManualTriggered(true);
    setCountdownTimerInSec(timeInSec);
    onIntervalEnd();
  }, [onIntervalEnd, timeInSec]);

  return { trigger, timeLeft: countdownTimerInSec };
};

const useStreamComments = ({ onEnableStreamComments, onNewLiveCommentWithOrder, isHighTrafficPost }) => {
  const [isContinuosStreaming, setIsContinuosStreaming] = useState(true);
  const [isForceDisableStream, setIsForceDisableStream] = useState(false);
  const pendingCommentsCountRef = useRef(0);
  const [pendingCommentsCount, setPendingCommentsCount] = useState(0);

  useEffect(() => {
    if (isHighTrafficPost) {
      setIsContinuosStreaming(false);
      setIsForceDisableStream(true);
    } else {
      setIsContinuosStreaming(true);
      setIsForceDisableStream(false);
    }
  }, [isHighTrafficPost]);

  const updatePendingCommentsCount = useCallback(() => {
    setPendingCommentsCount(pendingCommentsCountRef.current);
  }, []);
  useInterval({ timeInSec: 5, shouldStart: !isContinuosStreaming && !isHighTrafficPost, onIntervalEnd: updatePendingCommentsCount });

  const addNewPendingComments = useCallback(
    newComments => {
      const commentsWithOrder = newComments.filter(newComment => !!newComment.orderId);
      if (commentsWithOrder && commentsWithOrder.length > 0) {
        onNewLiveCommentWithOrder(commentsWithOrder);
      }

      pendingCommentsCountRef.current = pendingCommentsCountRef.current + newComments.length;
    },
    [onNewLiveCommentWithOrder]
  );

  const updateIsContinuosStreaming = useCallback(
    (newIsContinuosStreaming, { shouldPushComment = true, shouldForceDisableStream = false } = {}) => {
      if (newIsContinuosStreaming) {
        if (shouldPushComment && pendingCommentsCountRef.current > 0) {
          onEnableStreamComments();
        }

        pendingCommentsCountRef.current = 0;
        setPendingCommentsCount(0);
      }

      setIsContinuosStreaming(newIsContinuosStreaming);
      setIsForceDisableStream(shouldForceDisableStream);
    },
    [onEnableStreamComments]
  );

  return {
    isContinuosStreaming,
    isForceDisableStream,

    pendingCommentsCount,

    addNewPendingComments,
    updateIsContinuosStreaming
  };
};

/* ================================================== Local Components ================================================== */
const RestreamCommentsButton = ({ pendingCommentsCount, onClickStreamComments }) => {
  return (
    <StyledRestreamCommentsButtonContainer>
      <StyledRestreamCommentsButtonCount>{pendingCommentsCount >= 100 ? '99+' : pendingCommentsCount}</StyledRestreamCommentsButtonCount>
      <StyledRestreamCommentsButton type="primary" icon={<CommentOutlined />} onClick={onClickStreamComments} />
    </StyledRestreamCommentsButtonContainer>
  );
};

const RefreshCommentInterval = ({ t, isHighTrafficPost, refreshComments }) => {
  const [isAutoRefresh, setIsAutoRefresh] = useState(true);

  const { trigger: handleOnManualRefreshComment, timeLeft } = useInterval({
    timeInSec: 8,
    shouldStart: isHighTrafficPost && isAutoRefresh,
    shouldEnableCountdown: true,
    onIntervalEnd: refreshComments
  });

  return (
    <Row gutter={8} align="middle">
      <Col>
        <TextButton
          onClick={handleOnManualRefreshComment}
          icon={<ReloadOutlined />}
          text={t('pageOverview:live-comment-refresh-text', { timeLeft })}
        />
      </Col>
      <Col>
        <StyledCheckbox
          value={[isAutoRefresh]}
          options={[
            {
              value: true,
              label: t('pageOverview:live-comment-refresh-checkbox-text')
            }
          ]}
          onChange={newIsAutoRefreshArr => {
            const newIsAutoRefresh = newIsAutoRefreshArr.length > 0;
            setIsAutoRefresh(newIsAutoRefresh);
          }}
        />
      </Col>
    </Row>
  );
};

/* ================================================== Main Component ================================================== */
const FbLiveComments = ({
  history,
  store,
  postId,
  source,
  sourcePostId,
  hasValidSelectedPost,
  isHighTrafficPost,
  shouldShowMoreComments = false,
  refetchSelectedPost,
  onNewLiveCommentWithOrder,
  onViewOrderClick
}) => {
  const { t } = useTranslation(['common', 'pageOverview']);
  const refTitleContainer = useRef(null);

  const [selectedComment, setSelectedComment] = useState();
  const [isCreateOrderClicked, setIsCreateOrderClicked] = useState(false);
  const commentsCountIn10Sec = useRef(0);

  const canCreateOrder = store.subscriptionConfig.canCreateOrder;

  const { data: blacklist, isLoading: isLoadingBlacklist, refetch: refetchBlacklist } = useGetBlacklistedSocialMediaUserIds(source);

  const selectedCommentAlertInfo = useMemo(
    () =>
      guard(() =>
        selectedComment.systemRemarks.reduce(
          (alertInfo, systemRemark) => {
            const systemRemarkObj = new SystemRemark(systemRemark.code, systemRemark.subcode, systemRemark.metadata);

            return {
              ...alertInfo,
              localizedMessage: alertInfo.localizedMessage
                ? `${alertInfo.localizedMessage} ${systemRemarkObj.formatMessage(t)}`
                : systemRemarkObj.formatMessage(t)
            };
          },
          { localizedMessage: '', alertType: 'info' }
        )
      ),
    [t, selectedComment]
  );

  const {
    comments,
    isCommentsLoading,
    defaultPageSize,
    totalCommentCount,

    addNewComments,
    updateCommentWithNewOrderId,

    currentPage,
    updateCurrentPage,

    updateQuery,
    resetQuery,
    reloadComments
  } = useComments({ postId, shouldShowMoreComments, isLoadingBlacklist, blacklist });

  const handleOnCommentsCountIntervalEnd = useCallback(() => {
    if (commentsCountIn10Sec.current >= HIGH_TRAFFIC_NUM_OF_COMMENTS_THRESHOLD) {
      // Mark as high traffic and reload
      patchUpdatePostHighTraffic(postId).then(() => {
        refetchSelectedPost();
      });
    }
    commentsCountIn10Sec.current = 0;
  }, [postId, refetchSelectedPost]);
  useInterval({ timeInSec: 10, shouldStart: !isHighTrafficPost, onIntervalEnd: handleOnCommentsCountIntervalEnd });

  const handleOnCreateOrderClick = useCallback(
    comment => {
      if (canCreateOrder) {
        setSelectedComment(comment);
        setIsCreateOrderClicked(true);
      } else {
        notification.warning({
          duration: 0,
          placement: 'topRight',
          message: <b style={{ color: 'red' }}>{t('pageOverview:starter-user-order-limit-exceed-notification-message')}</b>,
          btn: (
            <TextButton
              onClick={() => {
                notification.destroy();
                history.push(getSubscriptionPlanRoute().path);
              }}
              icon={null}
              text={
                <>
                  {t('pageOverview:starter-user-order-limit-exceed-notification-button')} <RightOutlined />
                </>
              }
            />
          ),
          description: <p>{t('pageOverview:starter-user-order-limit-exceed-notification-desc')}</p>
        });
      }
    },
    [history, t, canCreateOrder]
  );

  const handleOnOrderSaveSuccessful = useCallback(
    orderId => {
      setIsCreateOrderClicked(false);
      updateCommentWithNewOrderId(selectedComment.commentId, orderId);
    },
    [selectedComment, updateCommentWithNewOrderId]
  );

  const handleOnOrderModalClose = useCallback(() => {
    setIsCreateOrderClicked(false);
  }, []);

  const handleOnBlacklistModalConfirm = useCallback(
    (sourceUserId, source, shouldBlacklistUser) => () => {
      patchSocialMediaUserBlacklistStatus(sourceUserId, source, shouldBlacklistUser).then(() => {
        refetchBlacklist();
      });
    },
    [refetchBlacklist]
  );

  // ========================================= Stream comments
  const {
    isContinuosStreaming,
    isForceDisableStream,
    pendingCommentsCount,

    addNewPendingComments,
    updateIsContinuosStreaming
  } = useStreamComments({ onEnableStreamComments: reloadComments, onNewLiveCommentWithOrder, isHighTrafficPost });

  const handleOnClickRestreamComments = useCallback(() => {
    refTitleContainer.current.scrollIntoView();

    updateIsContinuosStreaming(true, { shouldPushComment: !isForceDisableStream, shouldForceDisableStream: false });
    if (isForceDisableStream) {
      // Query state of table doesn't get reset, only our state
      // Ref Column to control the state for all table
      resetQuery();
    }
  }, [isForceDisableStream, resetQuery, updateIsContinuosStreaming]);

  // ========================================= Refresh comments interval
  const refreshComments = useCallback(() => {
    reloadComments();
    onNewLiveCommentWithOrder();
  }, [reloadComments, onNewLiveCommentWithOrder]);

  // ========================================= FB Live Stream
  const handleOnNewComments = useCallback(
    newComments => {
      commentsCountIn10Sec.current += newComments.length;
      if (isContinuosStreaming) {
        const commentsWithOrder = newComments.filter(newComment => !!newComment.orderId);
        if (commentsWithOrder && commentsWithOrder.length > 0) {
          onNewLiveCommentWithOrder(commentsWithOrder);
        }

        addNewComments(newComments);
      } else {
        addNewPendingComments(newComments);
      }
    },
    [isContinuosStreaming, addNewComments, addNewPendingComments, onNewLiveCommentWithOrder]
  );
  useFbLiveStream(source, sourcePostId, { shouldConnectToStream: !isHighTrafficPost, onNewComments: handleOnNewComments });

  // ========================================= Table
  const handleOnTableChange = useCallback(
    (pagination, filters, sorter) => {
      const hasFilter = !!Object.keys(filters).find(filterKey => {
        return !!filters[filterKey];
      });

      if (pagination.current > 1 || hasFilter) {
        updateIsContinuosStreaming(false, { shouldPushComment: false, shouldForceDisableStream: true });
      } else {
        updateIsContinuosStreaming(true, { shouldPushComment: false, shouldForceDisableStream: false });
      }

      handleOnAggregationTableChange({ pagination: { ...pagination }, filters, sorter }, updateQuery);
    },
    [updateQuery, updateIsContinuosStreaming]
  );

  return (
    <>
      <TitleContainerRow ref={refTitleContainer} justify="space-between">
        <Title>
          {t('pageOverview:live-comment-section-title')}{' '}
          {isHighTrafficPost && (
            <Tooltip title={t('pageOverview:live-comment-alert-title-high-traffic')}>
              <WarningFilled style={{ color: '#FFBA00', cursor: 'pointer' }} />
            </Tooltip>
          )}
        </Title>
        {isHighTrafficPost ? (
          <RefreshCommentInterval t={t} isHighTrafficPost={isHighTrafficPost} refreshComments={refreshComments} />
        ) : (
          <Tooltip title={isForceDisableStream ? t('pageOverview:live-comment-disable-stream-tooltip') : undefined} zIndex="1055">
            <StyledCheckbox
              value={[isContinuosStreaming]}
              isDisabled={isForceDisableStream}
              options={[
                {
                  value: true,
                  label: t('pageOverview:live-comment-section-checkbox')
                }
              ]}
              onChange={newIsContinuosStreamingArr => {
                const newIsContinuosStreaming = newIsContinuosStreamingArr.length > 0;
                updateIsContinuosStreaming(newIsContinuosStreaming);
              }}
            />
          </Tooltip>
        )}
      </TitleContainerRow>
      {hasValidSelectedPost ? (
        <FullWidthContainer>
          {isHighTrafficPost && (
            <Alert
              message={t('pageOverview:live-comment-alert-title-high-traffic')}
              description={t('pageOverview:live-comment-alert-description-high-traffic')}
              type="warning"
              showIcon
              closable
              style={{ marginBottom: '8px' }}
            />
          )}
          <Table
            size="small"
            rowKey={record => record.commentId}
            dataSource={comments}
            loading={isCommentsLoading}
            columns={constructColumns({
              t,
              source,
              handleOnCreateOrderClick,
              onViewOrderClick,
              handleOnBlacklistModalConfirm
            })}
            scroll={{ x: '100%', y: 560 }}
            pagination={{ total: totalCommentCount, current: currentPage, onChange: updateCurrentPage, defaultPageSize }}
            onChange={handleOnTableChange}
            locale={{
              triggerDesc: t('common:table-header-sort-trigger-desc'),
              triggerAsc: t('common:table-header-sort-trigger-asc'),
              cancelSort: t('common:table-header-sort-cancel-sort')
            }}
          />
        </FullWidthContainer>
      ) : (
        <div>{t('pageOverview:live-section-no-live-post-message')}</div>
      )}
      {pendingCommentsCount > 0 && (
        <RestreamCommentsButton pendingCommentsCount={pendingCommentsCount} onClickStreamComments={handleOnClickRestreamComments} />
      )}
      {isCreateOrderClicked && (
        <AddOrderModal
          visible={isCreateOrderClicked}
          comment={selectedComment}
          alertInfo={selectedCommentAlertInfo}
          onClose={handleOnOrderModalClose}
          onSaveSuccessful={handleOnOrderSaveSuccessful}
        />
      )}
    </>
  );
};

export default FbLiveComments;
