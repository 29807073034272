import styled from '@emotion/styled';
import { Image } from 'antd';
import { HexColorPicker, HexColorInput } from 'react-colorful';

export const ContentNoteText = styled.p`
  font-size: 12px;
  font-style: italic;
`;

export const StyledHexColorPicker = styled(HexColorPicker)`
  width: 200px;
  max-width: 100%;
  margin-bottom: 8px;
`;

export const StyledHexColorInput = styled(HexColorInput)`
  width: 200px;
  max-width: 100%;
  font-size: 14px;
  padding: 8px 11px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  margin-bottom: 24px;

  &:hover {
    border-color: ${props => props.theme.color.primary};
    border-right-width: 1px !important;
  }

  &:focus {
    border-color: ${props => props.theme.color.primary};
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(255, 186, 0, 0.2);
  }
`;

export const TemplateRecommendationContainer = styled.div`
  font-size: 12px;
  margin-top: 8px;
`;

export const TemplateRecommendationText = styled.p`
  margin-bottom: 4px;
`;

export const TemplateSelectionContainer = styled.div`
  border: 1px dashed ${props => props.theme.color.grey};
  padding: 16px;
  margin-bottom: 24px;
`;

export const TemplateSelectionImage = styled(Image)`
  cursor: ${props => (props.isSelection ? 'pointer' : 'default')};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
`;

export const TemplateSelectionLabel = styled.p`
  text-align: center;
`;
