import styled from '@emotion/styled';
import { Row, Button, Tooltip } from 'antd';

import Checkbox from 'components/Checkbox/Checkbox';

export const TitleContainerRow = styled(Row)``;

export const StyledCheckbox = styled(Checkbox)`
  display: flex;
  align-items: center;
`;

export const CommentContainer = styled.div`
  word-break: break-word;
`;

export const CommentIconContainer = styled.div`
  display: inline-block;
  margin-right: 4px;
  cursor: ${props => (props.canClick ? 'pointer' : undefined)};
`;

export const StyledRestreamCommentsButtonContainer = styled.div`
  position: fixed;
  right: 12px;
  bottom: 60px;
  z-index: 1;
  height: 44px;
  width: 44px;

  @media (min-width: 768px) {
    right: 28px;
  }
`;

export const StyledRestreamCommentsButton = styled(Button)`
  position: absolute;
  left: 0;
  bottom: 0;
`;

export const StyledRestreamCommentsButtonCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  color: ${props => props.theme.color.white};
  background-color: ${props => props.theme.color.red};
`;

export const NameContainer = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  width: 100%;
`;

export const NameLabel = styled.span`
  text-align: left;
  color: ${props => (props.isUserBlacklisted ? props.theme.color.grey : props.theme.color.lightTertiary)};
`;

export const StyledTooltip = styled(Tooltip)`
  width: 100%;
  overflow: hidden;
`;
