import styled from '@emotion/styled';
import { Select } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

export const DeleteButton = styled(DeleteOutlined)`
  font-size: 20px;
  vertical-align: middle;
  margin-left: 8px;
`;

export const PositionSelection = styled(Select)`
  width: 100%;
`;
