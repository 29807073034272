import { Card, Col, Row } from 'antd';

import Title from 'components/Title/Title';
import FullWidthContainer from 'components/FullWidthContainer/FullWidthContainer';

import CardActions from './../CardActions/CardActions';
import FormSelection from 'components/FormSelection/FormSelection';

import {
  ProductCardContentRow,
  ProductCardNameLabel,
  ProductCardInventoryLabel,
  ProductCardVarianceLabel,
  ProductCardInfoCol
} from './ProductSection.styles';

const ProductCard = ({ TYPE_PRODUCT, product = {}, positionSelection, position, onPositionChange, onDelete }) => {
  return (
    <Card style={{ height: '100%' }} bodyStyle={{ height: '100%' }}>
      <ProductCardContentRow>
        <Col span={24}>
          <ProductCardNameLabel ellipsis={{ rows: 2, tooltip: product.label }}>{product.label}</ProductCardNameLabel>
        </Col>
        <ProductCardInfoCol span={24}>
          <FullWidthContainer>
            <ProductCardVarianceLabel>Variances: {product.numberOfVariance}</ProductCardVarianceLabel>
            <ProductCardInventoryLabel>Total Inventory: {product.totalInventory}</ProductCardInventoryLabel>
          </FullWidthContainer>
          <CardActions
            options={positionSelection}
            position={position}
            onPositionChange={onPositionChange(TYPE_PRODUCT, position)}
            onDelete={onDelete(TYPE_PRODUCT, position)}
          />
        </ProductCardInfoCol>
      </ProductCardContentRow>
    </Card>
  );
};

const ProductSection = ({
  t,
  TYPE_PRODUCT,
  name,
  isBasicLayoutView,
  productSelection,
  handleOnProductSelectSelected,
  featuredProducts,
  productPositionSelection,
  handleOnPositionChange,
  handleOnDelete
}) => {
  return (
    <>
      {isBasicLayoutView && (
        <>
          <Title>{t('pageMicrositeSettings:title-product-section')}</Title>
          <span>{t('pageMicrositeSettings:desc-product-section')}</span>
        </>
      )}

      <FormSelection
        selections={productSelection}
        onSelect={handleOnProductSelectSelected}
        name={name}
        placeholder={t('pageMicrositeSettings:form-field-placeholder-product')}
        style={{ marginTop: 8 }}
      />
      <Row gutter={[16, 32]}>
        {featuredProducts.map((product, idx) => (
          <Col key={idx} span={24} sm={8} lg={isBasicLayoutView ? 6 : 8}>
            <ProductCard
              TYPE_PRODUCT={TYPE_PRODUCT}
              product={product}
              positionSelection={productPositionSelection}
              position={idx}
              onPositionChange={handleOnPositionChange}
              onDelete={handleOnDelete}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default ProductSection;
