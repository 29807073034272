import styled from '@emotion/styled';

export const ContentContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 8px;

  * {
    color: #000 !important;
  }

  @media (min-width: 768px) {
    width: 768px;
  }

  @media print {
    page-break-after: always;

    &:last-child {
      page-break-after: auto;
    }
  }
`;
