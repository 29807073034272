import { useEffect, useState, useMemo, useRef } from 'react';
import { Button, Col, Collapse, Divider, Form, message, Modal, Row, Skeleton } from 'antd';
import { CheckOutlined, ExclamationCircleOutlined, ShopOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { patchStoreMicrositeSettings, useGetStoreMicrositeSettings } from 'apis/store';
import { useGetProductsForMicrosite } from 'apis/product';

import { withAppContext } from 'contexts/AppContext/AppContext';

import FullWidthContainer from 'components/FullWidthContainer/FullWidthContainer';
import FormInput from 'components/FormInput/FormInput';
import FormRadioButton from 'components/FormRadioButton/FormRadioButton';

import { useFetchConstant } from 'hooks/constants';
import { guard, switchElementPosition } from 'utils/general';

import MainInfoSection from './components/MainInfoSection/MainInfoSection';
import ContentSection from './components/ContentSection/ContentSection';
import CategorySection from './components/CategorySection/CategorySection';
import ProductSection from './components/ProductSection/ProductSection';

const { confirm } = Modal;
const { useForm } = Form;
const { error } = message;

const TYPE_PRODUCT = 'product';
const TYPE_CATEGORY = 'category';
const DEFAULT_THEME_COLOR = '#ff7212';

const AUITO_BASE_URL = '.ulive.me';

const generatePositionSelection = maxNum => {
  const selections = [];
  for (let i = 1; i <= maxNum; i++) {
    selections.push({ label: i, value: i });
  }
  return selections;
};

const generateMicrositeUrl = (subdomainName, isWithProtocol = false) => {
  return `${isWithProtocol ? 'https://' : ''}${subdomainName}${AUITO_BASE_URL}`;
};

const getProductSelection = (products = [], featuredProducts) => {
  let selections = [...products];
  for (let i = 0; i < featuredProducts.length; i++) {
    const featuredProduct = featuredProducts[i];
    selections = selections.filter(selection => selection.value !== featuredProduct.value);
  }
  return selections;
};

const constructFeaturedProducts = (featuredProductIds = [], micrositeProducts = []) =>
  featuredProductIds.length > 0 && micrositeProducts.length > 0
    ? featuredProductIds.map(productId => micrositeProducts.find(product => product.value === productId))
    : [];

const MicrositeSettings = ({ store }) => {
  const { t } = useTranslation(['common', 'pageMicrositeSettings']);
  const [form] = useForm();
  const logoRef = useRef(null);
  const bannerRef = useRef(null);

  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [themeColor, setThemeColor] = useState(DEFAULT_THEME_COLOR);
  const [selectedLayoutView, setSelectedLayoutView] = useState();
  const [proLayoutContents, setProLayoutContents] = useState([]);

  const { isLoading: isLayoutViewsLoading, selection: layoutViews, data: layoutViewsConst } = useFetchConstant('micrositeLayoutViews');
  const { isLoading: isProductViewsLoading, selection: productViews, data: productViewsConst } = useFetchConstant('micrositeProductViews');
  const { isLoading: isMicrositeSettingsLoading, data: micrositeSettings, refetch: refetchMicrositeSettings } = useGetStoreMicrositeSettings();
  const { isLoading: isMicrositeProductsLoading, data: micrositeProducts } = useGetProductsForMicrosite();

  const productPositionSelection = useMemo(() => generatePositionSelection(featuredProducts.length), [featuredProducts]);
  const categoryPositionSelection = useMemo(() => generatePositionSelection(categories.length), [categories]);
  const productSelection = useMemo(() => getProductSelection(micrositeProducts, featuredProducts), [micrositeProducts, featuredProducts]);
  const isLoading = isLayoutViewsLoading || isProductViewsLoading || isMicrositeSettingsLoading || isMicrositeProductsLoading;
  const isNew = !micrositeSettings || !micrositeSettings.subdomainName;
  const isMicrositeProLayoutEnabled = guard(() => store.subscriptionConfig.isMicrositeProLayoutEnabled, false);
  const isProLayout = guard(() => isMicrositeProLayoutEnabled && selectedLayoutView === layoutViewsConst.PRO.code, false);

  useEffect(() => {
    if (!isLoading) {
      if (micrositeSettings) {
        form.setFieldsValue({
          ...micrositeSettings,
          ...(micrositeSettings.contents && {
            contents: micrositeSettings.contents.reduce((accumulateContent, content) => {
              return { ...accumulateContent, id: content._id, [content._id]: content };
            }, {})
          })
        });

        const constructedFeaturedProducts = constructFeaturedProducts(micrositeSettings.featuredProducts, micrositeProducts);
        setFeaturedProducts(constructedFeaturedProducts);
        setCategories(micrositeSettings.categories || []);
        setSelectedLayoutView(micrositeSettings.layoutView);

        if (micrositeSettings?.themeColor) {
          setThemeColor(micrositeSettings.themeColor);
        }
      } else {
        form.setFieldsValue({
          layoutView: layoutViewsConst.BASIC.code,
          productView: productViewsConst.DEFAULT.code
        });
        setSelectedLayoutView(layoutViewsConst.BASIC.code);
      }
    }
  }, [isLoading, form, micrositeSettings, micrositeProducts, layoutViewsConst, productViewsConst]);

  const handleOnSave = async (e, saveType = 'all') => {
    setIsSubmitting(true);
    e.preventDefault();

    try {
      const values = await form.validateFields();

      if (isNew) {
        confirm({
          title: t('pageMicrositeSettings:confirm-modal-create-microsite-title'),
          content: (
            <Row gutter={[0, 8]}>
              <Col span={24} style={{ fontWeight: 'bold' }}>
                {generateMicrositeUrl(values.subdomainName)}
              </Col>
              <Col span={24}>{t('pageMicrositeSettings:confirm-modal-create-microsite-content')}</Col>
            </Row>
          ),
          okText: t('common:modal-ok-text'),
          cancelText: t('common:modal-cancel-text'),
          icon: <ExclamationCircleOutlined />,
          onOk: async () => {
            patchStoreMicrositeSettings(values)
              .then(() => {
                message.success(t('common:create-success-message'));
                refetchMicrositeSettings();
              })
              .catch(ex => {
                error(ex.message);
              })
              .finally(() => {
                setIsSubmitting(false);
              });
          },
          onCancel() {
            setIsSubmitting(false);
          }
        });
      } else {
        let payload = {
          subdomainName: values.subdomainName,
          ...(saveType === 'all' && { ...values, featuredProducts: featuredProducts.map(product => product.value) }),
          ...(['all', 'category'].includes(saveType) && { categories, isAllowShowAllCategory: values.isAllowShowAllCategory })
        };

        if (['all', 'mainInfo'].includes(saveType)) {
          const [logo] = logoRef && logoRef.current ? await logoRef.current.update() : [];
          const [bannerV2] = bannerRef && bannerRef.current ? await bannerRef.current.update() : [];

          payload = {
            ...payload,
            themeColor,
            logo: logo || null,
            bannerV2: bannerV2 || null,
            ...(saveType === 'mainInfo' && {
              banner: values.banner,
              displayName: values.displayName,
              displayMessage: values.displayMessage,
              descriptions: values.descriptions,
              socialMediaLink: values.socialMediaLink,
              termsOfService: values.termsOfService,
              privacyPolicy: values.privacyPolicy,
              refundPolicy: values.refundPolicy
            })
          };
        }

        if ((saveType === 'all' && selectedLayoutView === layoutViewsConst.PRO.code) || saveType === 'content') {
          const formattedProLayoutContents = await Promise.all(
            proLayoutContents.map(async (proLayoutContent, index) => {
              return await Object.keys(proLayoutContent).reduce(async (accPromise, key) => {
                const acc = await accPromise;
                if (proLayoutContent[key]?.photoRef) {
                  const [photo] = proLayoutContent[key].photoRef.current ? await proLayoutContent[key].photoRef.current.update() : [];
                  return { ...acc, [key]: photo || null };
                }

                if (key === 'featuredProducts') {
                  return { ...acc, [key]: proLayoutContent[key].map(product => product.value) };
                }

                return { ...acc, [key]: proLayoutContent[key] };
              }, Promise.resolve({ ...values.contents[proLayoutContent.id], position: index }));
            })
          );

          payload = {
            ...payload,
            contents: formattedProLayoutContents
          };
        }

        patchStoreMicrositeSettings(payload)
          .then(updatedStore => {
            message.success(t('common:update-success-message'));
            // Update categories for new _id
            setCategories(updatedStore.micrositeSettings.categories);
          })
          .catch(ex => {
            error(ex.message);
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      }
    } catch (error) {
      error.errorFields.forEach(field => message.error(field.errors[0]));
      setIsSubmitting(false);
    }
  };

  const handleOnProductSelectSelected = selectedProductId => {
    const matchingProduct = productSelection.find(product => product.value === selectedProductId);
    if (matchingProduct) {
      const newFeaturedProducts = [...featuredProducts, matchingProduct];
      setFeaturedProducts(newFeaturedProducts);

      form.setFieldsValue({ product: '' });
    }
  };

  const handleOnAddCategoryButtonClick = () => {
    const newCategory = form.getFieldValue('category');

    if (newCategory) {
      const newCategories = [...categories, { label: newCategory }];
      setCategories(newCategories);

      form.setFieldsValue({ category: undefined });
    }
  };

  const handleOnPositionChange = (type, oldPosition) => newPosition => {
    if (type === TYPE_PRODUCT) {
      const newFeaturedProducts = switchElementPosition(featuredProducts, oldPosition, newPosition);
      setFeaturedProducts(newFeaturedProducts);
    } else if (type === TYPE_CATEGORY) {
      const newCategories = switchElementPosition(categories, oldPosition, newPosition);
      setCategories(newCategories);
    }
  };

  const handleOnCategoryUpdate = (position, oldCategory, newCategory) => {
    const newCategories = [...categories];
    newCategories[position] = { ...oldCategory, label: newCategory };
    setCategories(newCategories);
  };

  const handleOnDelete = (type, position) => () => {
    setIsSubmitting(true);

    const isTypeProduct = type === TYPE_PRODUCT;
    const isTypeCategory = type === TYPE_CATEGORY;

    let title = t('common:confirm-modal-delete-title');
    let content = t('common:confirm-modal-delete-content');
    if (isTypeProduct) {
      title = t('pageMicrositeSettings:confirm-modal-remove-featured-product-title');
      content = t('pageMicrositeSettings:confirm-modal-remove-featured-product-content');
    } else if (isTypeCategory) {
      title = t('pageMicrositeSettings:confirm-modal-remove-category-title');
      content = t('pageMicrositeSettings:confirm-modal-remove-category-content');
    }

    confirm({
      title,
      content,
      okText: t('common:modal-ok-text'),
      cancelText: t('common:modal-cancel-text'),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        if (isTypeProduct) {
          let newFeaturedProducts = [...featuredProducts];
          newFeaturedProducts.splice(position, 1);
          setFeaturedProducts(newFeaturedProducts);
        } else if (isTypeCategory) {
          let newCategories = [...categories];
          newCategories.splice(position, 1);
          setCategories(newCategories);
        }
        setIsSubmitting(false);
      },
      onCancel() {
        setIsSubmitting(false);
      }
    });
  };

  return (
    <FullWidthContainer>
      {isLoading ? (
        <Skeleton />
      ) : (
        <Form form={form} scrollToFirstError={true}>
          <Row gutter={32}>
            <Col span={24} sm={12}>
              {/* TODO: Fix hardcoded ulive.me */}
              <FormInput
                name="subdomainName"
                label={
                  isNew ? (
                    t('pageMicrositeSettings:form-field-label-domain-name')
                  ) : (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>{t('pageMicrositeSettings:form-field-label-domain-name')}</span>
                      <a
                        style={{ fontSize: '14px' }}
                        href={generateMicrositeUrl(micrositeSettings.subdomainName, true)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>
                          {t('pageMicrositeSettings:text-view-microsite')} <ShopOutlined />
                        </span>
                      </a>
                    </div>
                  )
                }
                placeholder="domain-name"
                requiredErrorMessage={isNew ? t('pageMicrositeSettings:form-field-required-error-message-subdomain-name') : undefined}
                extraProps={{ addonAfter: AUITO_BASE_URL }}
                type="domain"
                disabled={!isNew}
              />
            </Col>
            {isMicrositeProLayoutEnabled && (
              <Col span={12} sm={6}>
                <FormRadioButton
                  label={t('pageMicrositeSettings:form-field-label-layout-view')}
                  name="layoutView"
                  selections={layoutViews}
                  buttonStyle="solid"
                  onChange={e => setSelectedLayoutView(e.target.value)}
                />
              </Col>
            )}
            <Col span={12} sm={6}>
              <FormRadioButton
                label={t('pageMicrositeSettings:form-field-label-product-view')}
                name="productView"
                selections={productViews}
                buttonStyle="solid"
              />
            </Col>
          </Row>
          {!isNew && (
            <>
              {!isProLayout ? (
                <>
                  <MainInfoSection
                    t={t}
                    form={form}
                    storeId={store._id}
                    micrositeSettings={micrositeSettings}
                    themeColor={themeColor}
                    logoRef={logoRef}
                    bannerRef={bannerRef}
                    isBasicLayoutView
                    setThemeColor={setThemeColor}
                  />
                  <Divider />
                  <ProductSection
                    t={t}
                    TYPE_PRODUCT={TYPE_PRODUCT}
                    name="product"
                    isBasicLayoutView
                    productSelection={productSelection}
                    handleOnProductSelectSelected={handleOnProductSelectSelected}
                    featuredProducts={featuredProducts}
                    productPositionSelection={productPositionSelection}
                    handleOnPositionChange={handleOnPositionChange}
                    handleOnDelete={handleOnDelete}
                  />
                  <Divider />
                  <CategorySection
                    t={t}
                    TYPE_CATEGORY={TYPE_CATEGORY}
                    isSubmitting={isSubmitting}
                    isBasicLayoutView
                    categories={categories}
                    categoryPositionSelection={categoryPositionSelection}
                    onAddCategoryButtonClick={handleOnAddCategoryButtonClick}
                    onPositionChange={handleOnPositionChange}
                    onDelete={handleOnDelete}
                    onCategoryUpdate={handleOnCategoryUpdate}
                  />
                </>
              ) : (
                <>
                  <Collapse defaultActiveKey={['1']} expandIconPosition="right" style={{ marginBottom: '24px' }}>
                    <Collapse.Panel key="1" header={t('pageMicrositeSettings:title-main-info-section')}>
                      <MainInfoSection
                        t={t}
                        form={form}
                        storeId={store._id}
                        micrositeSettings={micrositeSettings}
                        themeColor={themeColor}
                        logoRef={logoRef}
                        bannerRef={bannerRef}
                        isSubmitting={isSubmitting}
                        setThemeColor={setThemeColor}
                        onSave={handleOnSave}
                      />
                    </Collapse.Panel>
                  </Collapse>
                  <Collapse defaultActiveKey={['1']} expandIconPosition="right" style={{ marginBottom: '24px' }}>
                    <Collapse.Panel key="1" header={t('pageMicrositeSettings:title-content-section')}>
                      <ContentSection
                        t={t}
                        form={form}
                        isSubmitting={isSubmitting}
                        storeId={store._id}
                        categories={categories}
                        micrositeProducts={micrositeProducts}
                        micrositeSettings={micrositeSettings}
                        proLayoutContents={proLayoutContents}
                        constructFeaturedProducts={constructFeaturedProducts}
                        setProLayoutContents={setProLayoutContents}
                        getProductSelection={getProductSelection}
                        generatePositionSelection={generatePositionSelection}
                        onSave={handleOnSave}
                      />
                    </Collapse.Panel>
                  </Collapse>
                  <Collapse defaultActiveKey={['1']} expandIconPosition="right" style={{ marginBottom: '24px' }}>
                    <Collapse.Panel key="1" header={t('pageMicrositeSettings:title-category-section')}>
                      <CategorySection
                        t={t}
                        TYPE_CATEGORY={TYPE_CATEGORY}
                        isSubmitting={isSubmitting}
                        categories={categories}
                        categoryPositionSelection={categoryPositionSelection}
                        onAddCategoryButtonClick={handleOnAddCategoryButtonClick}
                        onPositionChange={handleOnPositionChange}
                        onDelete={handleOnDelete}
                        onCategoryUpdate={handleOnCategoryUpdate}
                        onSave={handleOnSave}
                      />
                    </Collapse.Panel>
                  </Collapse>
                </>
              )}
            </>
          )}

          <Button type="primary" icon={<CheckOutlined />} onClick={handleOnSave} loading={isSubmitting}>
            {isNew ? t('pageMicrositeSettings:button-create-microsite') : t('pageMicrositeSettings:button-update-settings')}
          </Button>
        </Form>
      )}
    </FullWidthContainer>
  );
};

export default withAppContext(MicrositeSettings);
