import React, { useMemo, useCallback } from 'react';
import { Col, Input, Row } from 'antd';
import PropTypes from 'prop-types';

import FormItem from 'components/FormItem/FormItem';
import CurrencyDisplay from 'components/CurrencyDisplay/CurrencyDisplay';

import {
  CONTACT_NUMBER_REGEX,
  EMAIL_ADDRESS_REGEX,
  NRIC_REGEX,
  REGEX_NUMBER,
  PRICE_REGEX,
  KEYWORD_REGEX,
  USERNAME_REGEX,
  LUCKY_DRAW_KEYWORD_REGEX,
  DOMAIN_REGEX
} from 'utils/constants';

const { TextArea, Password } = Input;

const INPUT_TYPE_CONTACT_NUMBER = 'contactNumber';
const INPUT_TYPE_DEFAULT = 'default';
const INPUT_TYPE_EMAIL = 'email';
const INPUT_TYPE_FINANCIAL = 'financial';
const INPUT_TYPE_NUMERIC = 'numeric';
const INPUT_TYPE_NRIC = 'nric';
const INPUT_TYPE_TEXTAREA = 'textArea';
const INPUT_TYPE_PASSWORD = 'password';
const INPUT_TYPE_USERNAME = 'username';
const INPUT_TYPE_KEYWORD = 'keyword';
const INPUT_TYPE_LUCKY_DRAW_KEYWORD = 'luckyDrawKeyword';
const INPUT_TYPE_DOMAIN = 'domain';

const generateLabelNode = (type, label) => {
  if (type === INPUT_TYPE_FINANCIAL) {
    return <CurrencyDisplay prefix={label} />;
  }
  return label;
};

const generateNewExtraRules = (type, label, extraRules) => {
  const rules = [];

  if (type === INPUT_TYPE_FINANCIAL) {
    rules.push({
      pattern: PRICE_REGEX,
      message: `${label} must be numeric with two decimals only.`
    });
  }
  if (type === INPUT_TYPE_NUMERIC) {
    rules.push({
      pattern: REGEX_NUMBER,
      message: `${label || 'This field'} must be numeric.`
    });
  }
  if (type === INPUT_TYPE_CONTACT_NUMBER) {
    rules.push({
      pattern: CONTACT_NUMBER_REGEX,
      message: `${label} must meet the requirements.`
    });
  }
  if (type === INPUT_TYPE_EMAIL) {
    rules.push({
      pattern: EMAIL_ADDRESS_REGEX,
      message: 'Please enter a valid email address in lowercase. Eg: example@mail.com'
    });
  }
  if (type === INPUT_TYPE_USERNAME) {
    rules.push({
      pattern: USERNAME_REGEX,
      message: `Username must meet the requirements.`
    });
  }
  if (type === INPUT_TYPE_NRIC) {
    rules.push({
      pattern: NRIC_REGEX,
      message: `${label} must only contain 12 numbers without hyphen '-'.`
    });
  }
  if (type === INPUT_TYPE_KEYWORD) {
    rules.push({
      pattern: KEYWORD_REGEX,
      message: `Keyword can only contain chinese, lao and english word, number and symbol ".", "-".`
    });
  }
  if (type === INPUT_TYPE_LUCKY_DRAW_KEYWORD) {
    rules.push({
      pattern: LUCKY_DRAW_KEYWORD_REGEX,
      message: `Keyword can only contain chinese and english word, number. Space is allowed.`
    });
  }
  if (type === INPUT_TYPE_DOMAIN) {
    rules.push({
      pattern: DOMAIN_REGEX,
      message: (
        <Row>
          <Col>- Domain names can only contain alphabets, numbers and hyphens "-".</Col>
          <Col>- Domain names must not start or end with hyphens "-".</Col>
          <Col>- Domain names need at least 3 character & maximum 20 characters</Col>
        </Row>
      )
    });
  }

  if (extraRules) {
    rules.push(...extraRules);
  }

  return rules;
};

const FormInput = ({
  disabled,
  extraProps,
  label,
  name,
  placeholder,
  requiredErrorMessage,
  suffix,
  type,
  rows,
  extraRules,
  customMarginBottom,
  onChange
}) => {
  const newExtraRules = useMemo(() => generateNewExtraRules(type, label, extraRules), [type, label, extraRules]);
  const labelNode = useMemo(() => generateLabelNode(type, label), [type, label]);

  const getInputComponent = useCallback(() => {
    if (type === INPUT_TYPE_TEXTAREA) {
      return <TextArea rows={!!rows ? rows : 4} disabled={disabled} placeholder={placeholder} {...extraProps} />;
    } else if (type === INPUT_TYPE_PASSWORD) {
      return <Password disabled={disabled} placeholder={placeholder} suffix={suffix} type={type} {...extraProps} />;
    } else {
      return <Input disabled={disabled} placeholder={placeholder} suffix={suffix} type={type} {...extraProps} />;
    }
  }, [disabled, extraProps, placeholder, suffix, type, rows]);

  return (
    <FormItem
      extraRules={newExtraRules}
      name={name}
      label={labelNode}
      requiredErrorMessage={requiredErrorMessage}
      customMarginBottom={customMarginBottom}
      onChange={onChange}
    >
      {getInputComponent()}
    </FormItem>
  );
};

FormInput.propTypes = {
  extraProps: PropTypes.object,
  type: PropTypes.oneOf([
    INPUT_TYPE_CONTACT_NUMBER,
    INPUT_TYPE_DEFAULT,
    INPUT_TYPE_EMAIL,
    INPUT_TYPE_FINANCIAL,
    INPUT_TYPE_NUMERIC,
    INPUT_TYPE_NRIC,
    INPUT_TYPE_TEXTAREA,
    INPUT_TYPE_PASSWORD,
    INPUT_TYPE_USERNAME,
    INPUT_TYPE_KEYWORD,
    INPUT_TYPE_LUCKY_DRAW_KEYWORD,
    INPUT_TYPE_DOMAIN
  ])
};

FormInput.defaultProps = {
  extraProps: {},
  type: INPUT_TYPE_DEFAULT
};

export default FormInput;
