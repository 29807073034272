import { createRef, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Modal, Row, Skeleton, Space } from 'antd';
import { CloseOutlined, DownOutlined, ExclamationCircleOutlined, UpOutlined } from '@ant-design/icons';

import { switchElementPosition } from 'utils/general';

import FormInput from 'components/FormInput/FormInput';
import FormLabel from 'components/FormLabel/FormLabel';
import FormRadioButton from 'components/FormRadioButton/FormRadioButton';
import PhotoManager from 'components/PhotoManager/PhotoManager';

import { useFetchConstant } from 'hooks/constants';

import ProductSection from './../ProductSection/ProductSection';

import BannerOneImagePlaceholder from 'images/microsite/banner-one.png';
import BannerFourImagePlaceholder from 'images/microsite/banner-four.png';
import BannerFiveCtaImagePlaceholder from 'images/microsite/banner-five-cta.png';
import BannerThreeCtaImagePlaceholder from 'images/microsite/banner-three-cta.png';
import ProductScrollImagePlaceholder from 'images/microsite/product-scroll.png';
import ProductThreePerRowImagePlaceholder from 'images/microsite/product-three-per-row.png';
import TextShoutoutImagePlaceholder from 'images/microsite/text-shoutout.png';
import ProductCategoriesImagePlaceholder from 'images/microsite/product-categories.png';

import {
  ContentNoteText,
  StyledHexColorPicker,
  StyledHexColorInput,
  TemplateRecommendationContainer,
  TemplateRecommendationText,
  TemplateSelectionContainer,
  TemplateSelectionImage,
  TemplateSelectionLabel
} from './ContentSection.styles';

const { confirm } = Modal;

const DEFAULT_PRODUCT_CARD_COLOR = '#fafafa';

const constructSectionTemplate = (content, micrositeProducts, constructFeaturedProducts) => {
  switch (content.type) {
    case 'bannerOne':
      return {
        banner: {
          currentPhoto: content.banner || null,
          photoRef: createRef(null)
        }
      };
    case 'bannerFour':
      return {
        photo1: {
          currentPhoto: content.photo1 || null,
          photoRef: createRef(null)
        },
        photo2: {
          currentPhoto: content.photo2 || null,
          photoRef: createRef(null)
        },
        photo3: {
          currentPhoto: content.photo3 || null,
          photoRef: createRef(null)
        },
        photo4: {
          currentPhoto: content.photo4 || null,
          photoRef: createRef(null)
        }
      };
    case 'productScroll':
      return {
        cardColor: content.cardColor || DEFAULT_PRODUCT_CARD_COLOR,
        featuredProducts: constructFeaturedProducts(content.featuredProducts, micrositeProducts)
      };
    case 'productThreePerRow':
      return {
        cardColor: content.cardColor || DEFAULT_PRODUCT_CARD_COLOR,
        featuredProducts: constructFeaturedProducts(content.featuredProducts, micrositeProducts)
      };
    case 'bannerThreeCta':
      return {
        photo1: {
          currentPhoto: content.photo1 || null,
          photoRef: createRef(null)
        },
        photo2: {
          currentPhoto: content.photo2 || null,
          photoRef: createRef(null)
        },
        photo3: {
          currentPhoto: content.photo3 || null,
          photoRef: createRef(null)
        }
      };
    case 'bannerFiveCta':
      return {
        photo1: {
          currentPhoto: content.photo1 || null,
          photoRef: createRef(null)
        },
        photo2: {
          currentPhoto: content.photo2 || null,
          photoRef: createRef(null)
        },
        photo3: {
          currentPhoto: content.photo3 || null,
          photoRef: createRef(null)
        },
        photo4: {
          currentPhoto: content.photo4 || null,
          photoRef: createRef(null)
        },
        photo5: {
          currentPhoto: content.photo5 || null,
          photoRef: createRef(null)
        }
      };
    default:
      return {};
  }
};

const WrappedPhotoManager = ({ storeId, photo, photoRef, ...props }) => {
  const [currentPhoto, setCurrentPhoto] = useState(photo ? [photo] : []);
  return <PhotoManager value={currentPhoto} onChange={setCurrentPhoto} ref={photoRef} storeId={storeId} {...props} />;
};

const WrappedHexColorPicker = ({ contentIndex, color, setProLayoutContents }) => {
  const [currentColor, setCurrentColor] = useState(color);

  const handleChange = value => {
    setProLayoutContents(prevContents =>
      prevContents.map((content, index) => {
        if (index === contentIndex) {
          return { ...content, cardColor: value };
        }
        return content;
      })
    );
    setCurrentColor(value);
  };

  return (
    <>
      <StyledHexColorPicker color={currentColor} onChange={handleChange} />
      <StyledHexColorInput prefixed color={currentColor} onChange={handleChange} />
    </>
  );
};

const WrappedProductSection = ({
  t,
  form,
  name,
  contentIndex,
  micrositeProducts,
  featuredProducts,
  setProLayoutContents,
  getProductSelection,
  generatePositionSelection
}) => {
  const productSelection = useMemo(() => getProductSelection(micrositeProducts, featuredProducts), [
    getProductSelection,
    micrositeProducts,
    featuredProducts
  ]);
  const productPositionSelection = useMemo(() => generatePositionSelection(featuredProducts.length), [
    featuredProducts.length,
    generatePositionSelection
  ]);

  const handleOnProductSelectSelected = selectedProductId => {
    const matchingProduct = productSelection.find(product => product.value === selectedProductId);
    if (matchingProduct) {
      setProLayoutContents(prevContents =>
        prevContents.map((content, index) => {
          if (index === contentIndex) {
            return { ...content, featuredProducts: [...content.featuredProducts, matchingProduct] };
          }
          return content;
        })
      );

      form.setFieldsValue({ [name]: '' });
    }
  };

  const handleOnPositionChange = (type, oldPosition) => newPosition => {
    setProLayoutContents(prevContents =>
      prevContents.map((content, index) => {
        if (index === contentIndex) {
          return { ...content, featuredProducts: switchElementPosition(content.featuredProducts, oldPosition, newPosition) };
        }
        return content;
      })
    );
  };

  const handleOnDelete = (type, position) => () => {
    confirm({
      title: t('pageMicrositeSettings:confirm-modal-remove-featured-product-title'),
      content: t('pageMicrositeSettings:confirm-modal-remove-featured-product-content'),
      okText: t('common:modal-ok-text'),
      cancelText: t('common:modal-cancel-text'),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        setProLayoutContents(prevContents =>
          prevContents.map((content, index) => {
            if (index === contentIndex) {
              let newFeaturedProducts = [...content.featuredProducts];
              newFeaturedProducts.splice(position, 1);
              return { ...content, featuredProducts: newFeaturedProducts };
            }
            return content;
          })
        );
      }
    });
  };

  return (
    <ProductSection
      t={t}
      name={name}
      productSelection={productSelection}
      handleOnProductSelectSelected={handleOnProductSelectSelected}
      featuredProducts={featuredProducts}
      productPositionSelection={productPositionSelection}
      handleOnPositionChange={handleOnPositionChange}
      handleOnDelete={handleOnDelete}
    />
  );
};

const ContentSection = ({
  t,
  form,
  storeId,
  isSubmitting,
  categories,
  micrositeProducts,
  micrositeSettings,
  proLayoutContents,
  constructFeaturedProducts,
  setProLayoutContents,
  getProductSelection,
  generatePositionSelection,
  onSave
}) => {
  const { isLoading: isStoreContentLayoutTypesLoading, selection: storeContentLayoutTypes, data: storeContentLayoutTypesConst } = useFetchConstant(
    'storeContentLayoutTypes'
  );
  const storeContentLayoutTypesConstWithImage = useMemo(() => {
    if (!storeContentLayoutTypesConst) {
      return undefined;
    }
    return {
      BANNER_ONE: { ...storeContentLayoutTypesConst.BANNER_ONE, image: BannerOneImagePlaceholder },
      BANNER_FOUR: { ...storeContentLayoutTypesConst.BANNER_FOUR, image: BannerFourImagePlaceholder },
      PRODUCT_SCROLL: { ...storeContentLayoutTypesConst.PRODUCT_SCROLL, image: ProductScrollImagePlaceholder },
      PRODUCT_THREE_PER_ROW: { ...storeContentLayoutTypesConst.PRODUCT_THREE_PER_ROW, image: ProductThreePerRowImagePlaceholder },
      BANNER_THREE_CTA: { ...storeContentLayoutTypesConst.BANNER_THREE_CTA, image: BannerThreeCtaImagePlaceholder },
      BANNER_FIVE_CTA: { ...storeContentLayoutTypesConst.BANNER_FIVE_CTA, image: BannerFiveCtaImagePlaceholder },
      TEXT_SHOUTOUT: { ...storeContentLayoutTypesConst.TEXT_SHOUTOUT, image: TextShoutoutImagePlaceholder },
      PRODUCT_CATEGORIES: { ...storeContentLayoutTypesConst.PRODUCT_CATEGORIES, image: ProductCategoriesImagePlaceholder }
    };
  }, [storeContentLayoutTypesConst]);

  useEffect(() => {
    if (micrositeSettings.contents && micrositeSettings.contents.length > 0) {
      const oldContents = micrositeSettings.contents.map(content => {
        return {
          id: content._id,
          type: content.type,
          ...constructSectionTemplate(content, micrositeProducts, constructFeaturedProducts)
        };
      });
      setProLayoutContents(oldContents);
    }
  }, [constructFeaturedProducts, micrositeProducts, micrositeSettings, setProLayoutContents]);

  const handleOnNewSectionSelect = template => {
    const templateContents = constructSectionTemplate({ type: template }, micrositeProducts, constructFeaturedProducts);
    setProLayoutContents([...proLayoutContents, { id: String(new Date().getTime()), type: template, ...templateContents }]);
  };

  const handleOnMoveDownSection = index => {
    if (index < proLayoutContents.length - 1) {
      const newContents = switchElementPosition(proLayoutContents, index, index + 1);
      setProLayoutContents(newContents);
    }
  };

  const handleOnMoveUpSection = index => {
    if (index > 0) {
      const newContents = switchElementPosition(proLayoutContents, index, index - 1);
      setProLayoutContents(newContents);
    }
  };

  const handleOnRemoveSection = index => {
    confirm({
      title: t('pageMicrositeSettings:confirm-modal-remove-pro-layout-content-section-title'),
      content: t('pageMicrositeSettings:confirm-modal-remove-pro-layout-content-section-content'),
      okText: t('common:modal-ok-text'),
      cancelText: t('common:modal-cancel-text'),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        const newContents = [...proLayoutContents];
        newContents.splice(index, 1);
        setProLayoutContents(newContents);
      }
    });
  };

  return isStoreContentLayoutTypesLoading ? (
    <Skeleton loading={isStoreContentLayoutTypesLoading} />
  ) : (
    <>
      {proLayoutContents.map((content, index) => {
        return (
          <Card
            key={content.id}
            title={t('pageMicrositeSettings:title-content-section-index', { index: index + 1 })}
            size="small"
            style={{ marginBottom: '24px' }}
            extra={
              <Space>
                <Button
                  type="text"
                  size="small"
                  icon={<DownOutlined />}
                  disabled={index === proLayoutContents.length - 1}
                  onClick={() => {
                    handleOnMoveDownSection(index);
                  }}
                ></Button>
                <Button
                  type="text"
                  size="small"
                  icon={<UpOutlined />}
                  disabled={index === 0}
                  onClick={() => {
                    handleOnMoveUpSection(index);
                  }}
                ></Button>
                <Button
                  type="text"
                  size="small"
                  icon={<CloseOutlined />}
                  onClick={() => {
                    handleOnRemoveSection(index);
                  }}
                ></Button>
              </Space>
            }
          >
            {content.type === storeContentLayoutTypesConstWithImage.BANNER_ONE.code && (
              <Row gutter={36}>
                <Col span={24} md={6} style={{ borderRight: '2px solid #f0f0f0' }}>
                  <TemplateSelectionImage src={storeContentLayoutTypesConstWithImage.BANNER_ONE.image} preview={false} />
                  <TemplateRecommendationContainer>
                    <TemplateRecommendationText>
                      {t('pageMicrositeSettings:form-field-label-pro-layout-content-template-recommendation')}
                    </TemplateRecommendationText>
                    <ul>
                      <li>{t('pageMicrositeSettings:form-field-label-pro-layout-content-bannerOne-recommendation-point-1')}</li>
                      <li>{t('pageMicrositeSettings:form-field-label-pro-layout-content-bannerOne-recommendation-point-2')}</li>
                      <li>{t('pageMicrositeSettings:form-field-label-pro-layout-content-bannerOne-recommendation-point-3')}</li>
                      <li>{t('pageMicrositeSettings:form-field-label-pro-layout-content-bannerOne-recommendation-point-4')}</li>
                    </ul>
                  </TemplateRecommendationContainer>
                </Col>
                <Col span={24} md={18}>
                  <Row gutter={[16, 16]}>
                    <Col span={24} lg={8}>
                      <WrappedPhotoManager
                        storeId={storeId}
                        photo={content.banner.currentPhoto}
                        photoRef={content.banner.photoRef}
                        label={t('pageMicrositeSettings:form-field-label-pro-layout-content-bannerOne-banner-photo')}
                        buttonLabel={t('pageMicrositeSettings:form-field-label-upload-button')}
                        fileSizeThresholdToCompressInKb={200}
                        cropAspect={16 / 9}
                        shouldRemoveDocsMarkedForDelete
                        isHorizontalAlign
                      />
                    </Col>
                    <Col span={24} lg={16}>
                      <FormInput
                        name={['contents', content.id, 'url']}
                        label={t('pageMicrositeSettings:form-field-label-pro-layout-content-bannerOne-banner-url')}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
            {content.type === storeContentLayoutTypesConstWithImage.BANNER_FOUR.code && (
              <Row gutter={36}>
                <Col span={24} md={6} style={{ borderRight: '2px solid #f0f0f0' }}>
                  <TemplateSelectionImage src={storeContentLayoutTypesConstWithImage.BANNER_FOUR.image} preview={false} />
                  <TemplateRecommendationContainer>
                    <TemplateRecommendationText>
                      {t('pageMicrositeSettings:form-field-label-pro-layout-content-template-recommendation')}
                    </TemplateRecommendationText>
                    <ul>
                      <li>{t('pageMicrositeSettings:form-field-label-pro-layout-content-bannerFour-recommendation-point-1')}</li>
                      <li>{t('pageMicrositeSettings:form-field-label-pro-layout-content-bannerFour-recommendation-point-2')}</li>
                      <li>{t('pageMicrositeSettings:form-field-label-pro-layout-content-bannerFour-recommendation-point-3')}</li>
                      <li>{t('pageMicrositeSettings:form-field-label-pro-layout-content-bannerFour-recommendation-point-4')}</li>
                      <li>{t('pageMicrositeSettings:form-field-label-pro-layout-content-bannerFour-recommendation-point-5')}</li>
                    </ul>
                  </TemplateRecommendationContainer>
                </Col>
                <Col span={24} md={18}>
                  <p>{t('pageMicrositeSettings:form-field-label-pro-layout-content-bannerFour-upload-photos')}</p>
                  {Array.from(Array(4).keys()).map(index => {
                    return (
                      <Card
                        title={t('pageMicrositeSettings:form-field-label-pro-layout-content-bannerFour-photo', { index: index + 1 })}
                        size="small"
                        style={{ marginBottom: '24px' }}
                      >
                        <Row gutter={[16, 16]}>
                          <Col span={24} lg={8}>
                            <WrappedPhotoManager
                              storeId={storeId}
                              photo={content[`photo${index + 1}`].currentPhoto}
                              photoRef={content[`photo${index + 1}`].photoRef}
                              buttonLabel={t('pageMicrositeSettings:form-field-label-upload-button')}
                              fileSizeThresholdToCompressInKb={200}
                              cropAspect={1 / 1}
                              shouldRemoveDocsMarkedForDelete
                              isHorizontalAlign
                            />
                          </Col>
                          <Col span={24} lg={16}>
                            <FormInput
                              name={['contents', content.id, `photo${index + 1}_url`]}
                              label={t('pageMicrositeSettings:form-field-label-pro-layout-content-bannerFour-url')}
                            />
                          </Col>
                        </Row>
                      </Card>
                    );
                  })}
                </Col>
              </Row>
            )}
            {content.type === storeContentLayoutTypesConstWithImage.PRODUCT_SCROLL.code && (
              <Row gutter={36}>
                <Col span={24} md={6} style={{ borderRight: '2px solid #f0f0f0' }}>
                  <TemplateSelectionImage src={storeContentLayoutTypesConstWithImage.PRODUCT_SCROLL.image} preview={false} />
                  <TemplateRecommendationContainer>
                    <TemplateRecommendationText>
                      {t('pageMicrositeSettings:form-field-label-pro-layout-content-template-recommendation')}
                    </TemplateRecommendationText>
                    <ul>
                      <li>{t('pageMicrositeSettings:form-field-label-pro-layout-content-productScroll-recommendation-point-1')}</li>
                      <li>{t('pageMicrositeSettings:form-field-label-pro-layout-content-productScroll-recommendation-point-2')}</li>
                      <li>{t('pageMicrositeSettings:form-field-label-pro-layout-content-productScroll-recommendation-point-3')}</li>
                      <li>{t('pageMicrositeSettings:form-field-label-pro-layout-content-productScroll-recommendation-point-4')}</li>
                    </ul>
                  </TemplateRecommendationContainer>
                </Col>
                <Col span={24} md={18}>
                  <ContentNoteText>{t('pageMicrositeSettings:form-field-label-pro-layout-content-productScroll-note')}</ContentNoteText>
                  <Row gutter={36}>
                    <Col span={24} lg={16}>
                      <FormInput
                        name={['contents', content.id, 'title']}
                        label={t('pageMicrositeSettings:form-field-label-pro-layout-content-productScroll-title')}
                      />
                      <FormInput
                        name={['contents', content.id, 'subtitle']}
                        label={t('pageMicrositeSettings:form-field-label-pro-layout-content-productScroll-subtitle')}
                        type="textArea"
                        rows={5}
                        extraProps={{ maxLength: 2000, showCount: true }}
                      />
                    </Col>
                    <Col span={24} lg={8}>
                      <FormLabel children={t('pageMicrositeSettings:form-field-label-pro-layout-content-productScroll-card-color')} />
                      <WrappedHexColorPicker
                        contentIndex={index}
                        color={content.cardColor}
                        proLayoutContents={proLayoutContents}
                        setProLayoutContents={setProLayoutContents}
                      />
                    </Col>
                  </Row>
                  <FormLabel children={t('pageMicrositeSettings:form-field-label-pro-layout-content-productScroll-products')} />
                  <WrappedProductSection
                    t={t}
                    form={form}
                    name={`product_${content.id}`}
                    contentIndex={index}
                    micrositeProducts={micrositeProducts}
                    featuredProducts={content.featuredProducts}
                    setProLayoutContents={setProLayoutContents}
                    getProductSelection={getProductSelection}
                    generatePositionSelection={generatePositionSelection}
                  />
                </Col>
              </Row>
            )}
            {content.type === storeContentLayoutTypesConstWithImage.PRODUCT_THREE_PER_ROW.code && (
              <Row gutter={36}>
                <Col span={24} md={6} style={{ borderRight: '2px solid #f0f0f0' }}>
                  <TemplateSelectionImage src={storeContentLayoutTypesConstWithImage.PRODUCT_THREE_PER_ROW.image} preview={false} />
                  <TemplateRecommendationContainer>
                    <TemplateRecommendationText>
                      {t('pageMicrositeSettings:form-field-label-pro-layout-content-template-recommendation')}
                    </TemplateRecommendationText>
                    <ul>
                      <li>{t('pageMicrositeSettings:form-field-label-pro-layout-content-productThreePerRow-recommendation-point-1')}</li>
                      <li>{t('pageMicrositeSettings:form-field-label-pro-layout-content-productThreePerRow-recommendation-point-2')}</li>
                      <li>{t('pageMicrositeSettings:form-field-label-pro-layout-content-productThreePerRow-recommendation-point-3')}</li>
                      <li>{t('pageMicrositeSettings:form-field-label-pro-layout-content-productThreePerRow-recommendation-point-4')}</li>
                    </ul>
                  </TemplateRecommendationContainer>
                </Col>
                <Col span={24} md={18}>
                  <ContentNoteText>{t('pageMicrositeSettings:form-field-label-pro-layout-content-productThreePerRow-note')}</ContentNoteText>
                  <Row gutter={36}>
                    <Col span={24} lg={16}>
                      <FormInput
                        name={['contents', content.id, 'title']}
                        label={t('pageMicrositeSettings:form-field-label-pro-layout-content-productThreePerRow-title')}
                      />
                      <FormInput
                        name={['contents', content.id, 'subtitle']}
                        label={t('pageMicrositeSettings:form-field-label-pro-layout-content-productThreePerRow-subtitle')}
                        type="textArea"
                        rows={5}
                        extraProps={{ maxLength: 2000, showCount: true }}
                      />
                    </Col>
                    <Col span={24} lg={8}>
                      <FormLabel children={t('pageMicrositeSettings:form-field-label-pro-layout-content-productThreePerRow-card-color')} />
                      <WrappedHexColorPicker
                        contentIndex={index}
                        color={content.cardColor}
                        proLayoutContents={proLayoutContents}
                        setProLayoutContents={setProLayoutContents}
                      />
                    </Col>
                  </Row>
                  <FormLabel children={t('pageMicrositeSettings:form-field-label-pro-layout-content-productThreePerRow-products')} />
                  <WrappedProductSection
                    t={t}
                    form={form}
                    name={`product_${content.id}`}
                    contentIndex={index}
                    micrositeProducts={micrositeProducts}
                    featuredProducts={content.featuredProducts}
                    setProLayoutContents={setProLayoutContents}
                    getProductSelection={getProductSelection}
                    generatePositionSelection={generatePositionSelection}
                  />
                </Col>
              </Row>
            )}
            {content.type === storeContentLayoutTypesConstWithImage.BANNER_THREE_CTA.code && (
              <Row gutter={36}>
                <Col span={24} md={6} style={{ borderRight: '2px solid #f0f0f0' }}>
                  <TemplateSelectionImage src={storeContentLayoutTypesConstWithImage.BANNER_THREE_CTA.image} preview={false} />
                  <TemplateRecommendationContainer>
                    <TemplateRecommendationText>
                      {t('pageMicrositeSettings:form-field-label-pro-layout-content-template-recommendation')}
                    </TemplateRecommendationText>
                    <ul>
                      <li>{t('pageMicrositeSettings:form-field-label-pro-layout-content-bannerThreeCta-recommendation-point-1')}</li>
                      <li>{t('pageMicrositeSettings:form-field-label-pro-layout-content-bannerThreeCta-recommendation-point-2')}</li>
                      <li>{t('pageMicrositeSettings:form-field-label-pro-layout-content-bannerThreeCta-recommendation-point-3')}</li>
                      <li>{t('pageMicrositeSettings:form-field-label-pro-layout-content-bannerThreeCta-recommendation-point-4')}</li>
                    </ul>
                  </TemplateRecommendationContainer>
                </Col>
                <Col span={24} md={18}>
                  <Row gutter={[16, 16]}>
                    <Col span={24} lg={12}>
                      <FormInput
                        name={['contents', content.id, 'tagline']}
                        label={t('pageMicrositeSettings:form-field-label-pro-layout-content-bannerThreeCta-tagline')}
                        type="textArea"
                        rows={5}
                        extraProps={{ maxLength: 80, showCount: true }}
                      />
                    </Col>
                    <Col span={24} lg={12}>
                      <FormInput
                        name={['contents', content.id, 'url']}
                        label={t('pageMicrositeSettings:form-field-label-pro-layout-content-bannerThreeCta-url')}
                      />
                    </Col>
                    {Array.from(Array(3).keys()).map(index => {
                      return (
                        <Col span={24} sm={12}>
                          <WrappedPhotoManager
                            storeId={storeId}
                            photo={content[`photo${index + 1}`].currentPhoto}
                            photoRef={content[`photo${index + 1}`].photoRef}
                            label={t(`pageMicrositeSettings:form-field-label-pro-layout-content-bannerThreeCta-photo${index + 1}`)}
                            buttonLabel={t('pageMicrositeSettings:form-field-label-upload-button')}
                            fileSizeThresholdToCompressInKb={200}
                            cropAspect={index === 0 ? 9 / 16 : index === 1 ? 1 / 1 : 16 / 9}
                            shouldRemoveDocsMarkedForDelete
                            isHorizontalAlign
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
            )}
            {content.type === storeContentLayoutTypesConstWithImage.BANNER_FIVE_CTA.code && (
              <Row gutter={36}>
                <Col span={24} md={6} style={{ borderRight: '2px solid #f0f0f0' }}>
                  <TemplateSelectionImage src={storeContentLayoutTypesConstWithImage.BANNER_FIVE_CTA.image} preview={false} />
                  <TemplateRecommendationContainer>
                    <TemplateRecommendationText>
                      {t('pageMicrositeSettings:form-field-label-pro-layout-content-template-recommendation')}
                    </TemplateRecommendationText>
                    <ul>
                      <li>{t('pageMicrositeSettings:form-field-label-pro-layout-content-bannerFiveCta-recommendation-point-1')}</li>
                      <li>{t('pageMicrositeSettings:form-field-label-pro-layout-content-bannerFiveCta-recommendation-point-2')}</li>
                      <li>{t('pageMicrositeSettings:form-field-label-pro-layout-content-bannerFiveCta-recommendation-point-3')}</li>
                      <li>{t('pageMicrositeSettings:form-field-label-pro-layout-content-bannerFiveCta-recommendation-point-4')}</li>
                    </ul>
                  </TemplateRecommendationContainer>
                </Col>
                <Col span={24} md={18}>
                  <Row gutter={[16, 16]}>
                    <Col span={24} lg={12}>
                      <FormInput
                        name={['contents', content.id, 'tagline']}
                        label={t('pageMicrositeSettings:form-field-label-pro-layout-content-bannerFiveCta-tagline')}
                        type="textArea"
                        rows={5}
                        extraProps={{ maxLength: 80, showCount: true }}
                      />
                    </Col>
                    <Col span={24} lg={12}>
                      <FormInput
                        name={['contents', content.id, 'url']}
                        label={t('pageMicrositeSettings:form-field-label-pro-layout-content-bannerFiveCta-url')}
                      />
                    </Col>
                    {Array.from(Array(5).keys()).map(index => {
                      return (
                        <Col span={24} sm={12}>
                          <WrappedPhotoManager
                            storeId={storeId}
                            photo={content[`photo${index + 1}`].currentPhoto}
                            photoRef={content[`photo${index + 1}`].photoRef}
                            label={t(`pageMicrositeSettings:form-field-label-pro-layout-content-bannerFiveCta-photo${index + 1}`)}
                            buttonLabel={t('pageMicrositeSettings:form-field-label-upload-button')}
                            fileSizeThresholdToCompressInKb={200}
                            cropAspect={index === 4 ? 9 / 16 : 1 / 1}
                            shouldRemoveDocsMarkedForDelete
                            isHorizontalAlign
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
            )}
            {content.type === storeContentLayoutTypesConstWithImage.TEXT_SHOUTOUT.code && (
              <Row gutter={36}>
                <Col span={24} md={6} style={{ borderRight: '2px solid #f0f0f0' }}>
                  <TemplateSelectionImage src={storeContentLayoutTypesConstWithImage.TEXT_SHOUTOUT.image} preview={false} />
                  <TemplateRecommendationContainer>
                    <TemplateRecommendationText>
                      {t('pageMicrositeSettings:form-field-label-pro-layout-content-template-recommendation')}
                    </TemplateRecommendationText>
                    <ul>
                      <li>{t('pageMicrositeSettings:form-field-label-pro-layout-content-textShoutout-recommendation-point-1')}</li>
                      <li>{t('pageMicrositeSettings:form-field-label-pro-layout-content-textShoutout-recommendation-point-2')}</li>
                      <li>{t('pageMicrositeSettings:form-field-label-pro-layout-content-textShoutout-recommendation-point-3')}</li>
                    </ul>
                  </TemplateRecommendationContainer>
                </Col>
                <Col span={24} md={18}>
                  <ContentNoteText>{t('pageMicrositeSettings:form-field-label-pro-layout-content-textShoutout-note')}</ContentNoteText>
                  <Row gutter={16}>
                    <Col span={24} lg={12}>
                      <FormInput
                        label={t('pageMicrositeSettings:form-field-label-pro-layout-content-textShoutout-title')}
                        name={['contents', content.id, 'title']}
                      />
                      <FormRadioButton
                        name={['contents', content.id, 'textColor']}
                        label={t('pageMicrositeSettings:form-field-label-pro-layout-content-textShoutout-text-color')}
                        buttonStyle="solid"
                        selections={[
                          {
                            value: 'black',
                            label: t('pageMicrositeSettings:form-field-label-pro-layout-content-textShoutout-text-color-selection-black')
                          },
                          {
                            value: 'white',
                            label: t('pageMicrositeSettings:form-field-label-pro-layout-content-textShoutout-text-color-selection-white')
                          }
                        ]}
                      />
                    </Col>
                    <Col span={24} lg={12}>
                      <FormInput
                        name={['contents', content.id, 'message']}
                        label={t('pageMicrositeSettings:form-field-label-pro-layout-content-textShoutout-message')}
                        type="textArea"
                        rows={5}
                        extraProps={{ maxLength: 2000, showCount: true }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
            {content.type === storeContentLayoutTypesConstWithImage.PRODUCT_CATEGORIES.code && (
              <Row gutter={36}>
                <Col span={24} md={6} style={{ borderRight: '2px solid #f0f0f0' }}>
                  <TemplateSelectionImage src={storeContentLayoutTypesConstWithImage.PRODUCT_CATEGORIES.image} preview={false} />
                </Col>
                <Col span={24} md={18}>
                  <ContentNoteText>{t('pageMicrositeSettings:form-field-label-pro-layout-content-productCategories-note')}</ContentNoteText>
                  <p>
                    {t('pageMicrositeSettings:form-field-label-pro-layout-content-productCategories-categories-you-have', {
                      categories: categories.map(category => category.label).join(', ')
                    })}
                  </p>
                </Col>
              </Row>
            )}
          </Card>
        );
      })}

      <TemplateSelectionContainer>
        <TemplateSelectionLabel>
          {t('pageMicrositeSettings:form-field-label-pro-layout-content-select-template-for-new-section')}
        </TemplateSelectionLabel>
        <Row gutter={[16, 16]}>
          {Object.values(storeContentLayoutTypesConstWithImage).map(type => {
            return (
              <Col span={24} sm={12} md={6}>
                <TemplateSelectionImage onClick={() => handleOnNewSectionSelect(type.code)} src={type.image} preview={false} isSelection />
              </Col>
            );
          })}
        </Row>
      </TemplateSelectionContainer>

      <Button
        type="primary"
        loading={isSubmitting}
        onClick={e => {
          onSave(e, 'content');
        }}
      >
        {t('common:form-submit-create-button-text')}
      </Button>
    </>
  );
};

export default ContentSection;
