import styled from '@emotion/styled';
import { Tag } from 'antd';

export const StyledTag = styled(Tag)`
  width: 100%;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.theme.color.black};

  @media (min-width: 768px) {
    width: auto;
  }
`;
