import { GiftOutlined } from '@ant-design/icons';
import { Col, Row, List, Tooltip } from 'antd';

import { guard } from 'utils/general';
import { formatToDateTimeWithPMAM } from 'utils/date';

import ShippingFeeIcon from 'components/Icon/ShippingFeeIcon';
import BulkPurchaseIcon from 'components/Icon/BulkPurchaseIcon';
import PriceDisplay from 'components/PriceDisplay/PriceDisplay';
import CurrencyDisplay from 'components/CurrencyDisplay/CurrencyDisplay';
import InvoiceHeader from './../InvoiceHeader/InvoiceHeader';

import { ContentContainer, InvoiceContentContainer, OrderInfoTitleText, PriceBreakdownContainer, PriceBreakdownItem } from './CompactInvoice.styles';

const OrderProductList = ({ t, products, productTypesConst, totalWeightInKG }) => {
  const totalItemCount = products.reduce((totalItemCount, product) => {
    return totalItemCount + product.quantity;
  }, 0);

  return (
    <List
      size="small"
      dataSource={products}
      footer={
        <div style={{ padding: '16px 8px 0px' }}>
          <p style={{ marginBottom: '0px', padding: '0 8px', fontSize: '12px' }}>
            {t('pageOrderDetails:order-display-desc-label-total-item-count', {
              itemCount: totalItemCount
            })}
          </p>
        </div>
      }
      renderItem={(product, index) => (
        <List.Item key={product.matchingKeyword}>
          <List.Item.Meta
            title={
              <div style={{ display: 'flex' }}>
                <span>
                  {index + 1}. [{product.matchingKeyword}] {product.label}
                </span>
                <ShowShippingIndicator t={t} orderProduct={product} />
                <ShowBulkPurchaseIndicator t={t} orderProduct={product} />
                <ShowGiftIndicator t={t} orderProduct={product} productTypesConst={productTypesConst} />
              </div>
            }
            description={<span style={{ fontSize: '12px' }}>{formatToDateTimeWithPMAM(product.purchasedDate)}</span>}
          />
          <div style={{ width: '30%' }}>
            <Row justify="space-between">
              <Col>
                <span>
                  {product.quantity} x <PriceDisplay amount={product.priceDetails.price} isHideSymbol />
                </span>
              </Col>
              <Col>
                <PriceDisplay amount={product.priceDetails.totalAmount} isHideSymbol />
              </Col>
            </Row>
          </div>
        </List.Item>
      )}
    />
  );
};

const ShowShippingIndicator = ({ t, orderProduct }) => {
  const hasShippingFeeTier = guard(() => !!orderProduct.priceDetails.shippingFeeTier, false);

  return hasShippingFeeTier ? (
    <Tooltip title={t('pageOrderDetails:order-display-desc-label-shipping-fee-icon')}>
      <div>
        <ShippingFeeIcon width={20} height={20} style={{ marginLeft: '4px', marginBottom: '2px' }} />
      </div>
    </Tooltip>
  ) : null;
};

const ShowBulkPurchaseIndicator = ({ t, orderProduct }) => {
  const hasBulkPurchaseTier = guard(() => !!orderProduct.priceDetails.bulkPurchaseTier, false);

  return hasBulkPurchaseTier ? (
    <Tooltip title={t('pageOrderDetails:order-display-desc-label-bulkpurchase-icon')}>
      <div>
        <BulkPurchaseIcon width={16} height={16} style={{ marginLeft: '4px', marginBottom: '4px' }} />
      </div>
    </Tooltip>
  ) : null;
};

const ShowGiftIndicator = ({ t, orderProduct, productTypesConst }) => {
  const orderProductType = guard(() => orderProduct.type, productTypesConst.NORMAL.code);

  return orderProductType === productTypesConst.GIFT.code ? (
    <Tooltip title={t('pageOrderDetails:order-display-desc-label-gift-icon')}>
      <div>
        <GiftOutlined width={16} height={16} style={{ marginLeft: '4px', marginBottom: '4px' }} />
      </div>
    </Tooltip>
  ) : null;
};

const PriceBreakdownListItem = ({ label, extraDesc, amount, isDeduct, isHighlightAmout }) => {
  return (
    <Row>
      <PriceBreakdownItem span={18}>
        <CurrencyDisplay prefix={label} />
        {extraDesc && <i>({extraDesc})</i>}
      </PriceBreakdownItem>
      <PriceBreakdownItem span={6} style={{ fontWeight: isHighlightAmout ? 'bold' : 'normal' }}>
        <PriceDisplay amount={amount} isHideSymbol isDeduct={isDeduct} />
      </PriceBreakdownItem>
    </Row>
  );
};

const CompactInvoice = ({ t, order, countries, productTypesConst }) => {
  return (
    <ContentContainer>
      <InvoiceHeader t={t} order={order} countries={countries} isCompact />
      <InvoiceContentContainer>
        <OrderInfoTitleText>
          <b>{t('pageOrderDetails:order-display-desc-label-order-info')}</b>
        </OrderInfoTitleText>
        {order.products && (
          <OrderProductList t={t} products={order.products} productTypesConst={productTypesConst} totalWeightInKG={order.shipment.totalWeightInKG} />
        )}
        <PriceBreakdownContainer>
          <PriceBreakdownListItem label={t('pageOrderDetails:order-display-desc-label-subtotal')} amount={order.subtotalPrice} />
          <PriceBreakdownListItem label={t('pageOrderDetails:order-display-desc-label-shipping-fee')} amount={order.shipment.shippingFee} />
          {!!order.shipment.productShippingFee && (
            <PriceBreakdownListItem
              label={t('pageOrderDetails:order-display-desc-label-product-shipping-fee')}
              amount={order.shipment.productShippingFee}
            />
          )}
          {order.extraFee && !!order.extraFee.amount && (
            <PriceBreakdownListItem label={t('pageOrderDetails:order-display-desc-label-extra-fee')} amount={order.extraFee.amount} />
          )}
          {order.discount && !!order.discount.amount && (
            <PriceBreakdownListItem
              label={t('pageOrderDetails:order-display-desc-label-discount')}
              amount={order.discount.amount}
              extraDesc={order.discount.remarks}
              isDeduct
            />
          )}
          {order.promotionDetails && order.promotionDetails.promotions && order.promotionDetails.promotions.length > 0 && (
            <PriceBreakdownListItem
              label={`${t('pageOrderDetails:order-display-desc-label-promo')} (${order.promotionDetails.promotions
                .map(promotion => promotion.code)
                .join(', ')})`}
              amount={order.promotionDetails.discountAmount}
              isDeduct
            />
          )}
          {!!(order.point && order.point.redeem && order.point.redeem.discountAmount) && (
            <PriceBreakdownListItem
              label={`${t('pageOrderDetails:order-display-desc-label-points-redeemed')} (${t(
                'pageOrderDetails:order-display-desc-tooltip-point-redeemed-message',
                {
                  point: order.point.redeem.amount
                }
              )})`}
              amount={order.point.redeem.discountAmount}
              isDeduct
            />
          )}
          <PriceBreakdownListItem label={t('pageOrderDetails:order-display-desc-label-total')} amount={order.totalAmount} isHighlightAmout />
          <PriceBreakdownListItem label={t('pageOrderDetails:order-display-desc-label-balance')} amount={order.balanceAmount} isHighlightAmout />
        </PriceBreakdownContainer>
        <br />
      </InvoiceContentContainer>
      <p style={{ fontSize: '12px' }}>This invoice is computer generated and no signature is required</p>
    </ContentContainer>
  );
};

export default CompactInvoice;
