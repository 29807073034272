import styled from '@emotion/styled';

export const HeaderContainer = styled.div`
  text-align: center;
  padding: ${props => (props.isCompact ? '24px 24px 8px' : '24px 0')};
`;

export const StoreInfoContainer = styled.div`
  margin-bottom: 16px;
`;

export const StoreNameText = styled.h2`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0px;
`;

export const StoreAddressText = styled.h3`
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 0px;
`;

export const DateText = styled.p`
  font-size: 12px;
`;

export const ShipmentContainer = styled.div`
  text-align: left;
  border-bottom: 1px dashed #000000;
  border-top: 1px dashed #000000;
  padding: 8px 0px;
`;

export const ShipmentDetailText = styled.p`
  font-size: 14px;
  margin-bottom: 4px;
`;

export const ShipmentDetailContact = styled.p`
  font-size: 16px;
  font-weight: 700;
`;
