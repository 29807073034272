import { Button, Card, Col, Input, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import FormCheckboxToggle from 'components/Checkbox/FormCheckboxToggle/FormCheckboxToggle';
import FormInput from 'components/FormInput/FormInput';
import Title from 'components/Title/Title';

import CardActions from './../CardActions/CardActions';

const CategoryCard = ({ TYPE_CATEGORY, category, positionSelection, position, onPositionChange, onDelete, onUpdate }) => {
  return (
    <Card size="small" style={{ lineHeight: '40px' }}>
      <Input value={category.label} onChange={e => onUpdate(position, category, e.target.value)} style={{ marginBottom: 14 }} />
      <CardActions
        options={positionSelection}
        position={position}
        onPositionChange={onPositionChange(TYPE_CATEGORY, position)}
        onDelete={onDelete(TYPE_CATEGORY, position)}
      />
    </Card>
  );
};

const CategorySection = ({
  TYPE_CATEGORY,
  t,
  isSubmitting,
  isBasicLayoutView,
  categories,
  categoryPositionSelection,
  onAddCategoryButtonClick,
  onPositionChange,
  onDelete,
  onCategoryUpdate,
  onSave
}) => {
  return (
    <>
      {isBasicLayoutView && <Title>{t('pageMicrositeSettings:title-category-section')}</Title>}
      <p>{t('pageMicrositeSettings:desc-category-section')}</p>
      <FormCheckboxToggle
        name="isAllowShowAllCategory"
        label={t('pageMicrositeSettings:form-field-label-show-all-category-checkbox')}
        formItemStyle={{ marginBottom: 0 }}
      />
      <Row gutter={16} style={{ marginTop: 16 }}>
        <Col flex="auto">
          <FormInput
            name="category"
            placeholder={t('pageMicrositeSettings:form-field-placeholder-category')}
            extraProps={{ onPressEnter: onAddCategoryButtonClick }}
          />
        </Col>
        <Col>
          <Button icon={<PlusOutlined />} onClick={onAddCategoryButtonClick} loading={isSubmitting}>
            {t('pageMicrositeSettings:button-add-category')}
          </Button>
        </Col>
      </Row>
      <Row gutter={[16, 32]}>
        {categories.map((category, idx) => (
          <Col key={idx} span={24} sm={8} lg={6}>
            <CategoryCard
              category={category}
              positionSelection={categoryPositionSelection}
              position={idx}
              placeholder={t('pageMicrositeSettings:form-field-placeholder-category')}
              onPositionChange={onPositionChange}
              onDelete={onDelete}
              onUpdate={onCategoryUpdate}
              TYPE_CATEGORY={TYPE_CATEGORY}
            />
          </Col>
        ))}
      </Row>
      {!isBasicLayoutView && (
        <Button
          type="primary"
          loading={isSubmitting}
          onClick={e => {
            onSave(e, 'category');
          }}
        >
          {t('common:form-submit-create-button-text')}
        </Button>
      )}
    </>
  );
};

export default CategorySection;
