import { Col, Row } from 'antd';

import { getFormattedContact, getLabelOfConstant, guard } from 'utils/general';
import { formatToDateTimeWithPMAM } from 'utils/date';

import {
  DateText,
  HeaderContainer,
  ShipmentContainer,
  ShipmentDetailContact,
  ShipmentDetailText,
  StoreInfoContainer,
  StoreNameText,
  StoreAddressText
} from './InvoiceHeader.styles';

const getAddressText = ({ addressLine1, addressLine2, city, zipcode, state, country }, countries) =>
  `${addressLine1 ? addressLine1 + ' ' : ''}${addressLine2 ? addressLine2 + ', ' : ''}${zipcode || ''} ${city ? city + ', ' : ''}${
    state ? state + ', ' : ''
  }${country ? getLabelOfConstant(country, countries) + '.' : ''}`;

const formatAddressSubInfo = ({ city, zipcode, state, country }, countries) =>
  `${zipcode || ''} ${city ? city + ', ' : ''}${state ? state + ', ' : ''}${country ? getLabelOfConstant(country, countries) : ''}`;

const ShipmentInfoItem = ({ t, shipment, customer, countries }) => {
  return (
    <Row gutter={32} justify="space-between">
      <Col span={14}>
        <ShipmentDetailText>
          <ShipmentDetailContact>
            {shipment.recipientName}{' '}
            {customer && customer.source && customer.sourceUserName ? `| ${customer.source.toUpperCase()}:${customer.sourceUserName}` : ''}
            {shipment && shipment.recipientContact && ` (${getFormattedContact(shipment.recipientContact)})`}
          </ShipmentDetailContact>
        </ShipmentDetailText>
        <ShipmentDetailText>{shipment.address && getAddressText(shipment.address, countries)}</ShipmentDetailText>
      </Col>
      <Col span={10}>
        {shipment.label && (
          <ShipmentDetailText>
            {t('pageOrderDetails:order-display-desc-label-shipment-method')}: {shipment.label}
          </ShipmentDetailText>
        )}
        {shipment.remarks && (
          <ShipmentDetailText>
            {t('pageOrderDetails:order-display-desc-label-shipment-remarks')}: <i> {shipment.remarks}</i>
          </ShipmentDetailText>
        )}
      </Col>
    </Row>
  );
};

const InvoiceHeader = ({ t, order, countries, isCompact }) => {
  const hasSubInfo = guard(
    () => order.store.address.city || order.store.address.zipcode || order.store.address.state || order.store.address.country,
    false
  );

  return (
    <HeaderContainer isCompact={isCompact}>
      <StoreInfoContainer>
        <StoreNameText>{order.store.name}</StoreNameText>
        {order.store.address?.addressLine1 && <StoreAddressText>{order.store.address.addressLine1}</StoreAddressText>}
        {order.store.address?.addressLine2 && <StoreAddressText>{order.store.address.addressLine2}</StoreAddressText>}
        {hasSubInfo && <StoreAddressText>{formatAddressSubInfo(order.store.address, countries)}</StoreAddressText>}
      </StoreInfoContainer>
      <h1 style={{ fontSize: '18px', marginBottom: '0px' }}>
        <b>
          {t('pageOrderDetails:pdf-invoice-title')} #{order.orderNumber}
        </b>
      </h1>
      <DateText>{formatToDateTimeWithPMAM(order.createdAt)}</DateText>
      <ShipmentContainer>
        {order.shipment && <ShipmentInfoItem t={t} shipment={order.shipment} customer={order.customer} countries={countries} />}{' '}
        <p style={{ fontSize: '12px' }}>
          <i>{order.remarks ? `"${order.remarks}"` : undefined}</i>
        </p>
      </ShipmentContainer>
    </HeaderContainer>
  );
};

export default InvoiceHeader;
