import { Col, Row } from 'antd';

import { DeleteButton, PositionSelection } from './CardActions.styles';

const CardActions = ({ options, position, onPositionChange, onDelete }) => {
  return (
    <Row align="middle" style={{ width: '100%' }}>
      <Col flex="auto">
        <PositionSelection options={options} value={position + 1} onChange={pos => onPositionChange(pos - 1)} />
      </Col>
      <Col>
        <DeleteButton onClick={onDelete} />
      </Col>
    </Row>
  );
};

export default CardActions;
