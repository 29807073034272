import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'antd';

import FormInput from 'components/FormInput/FormInput';
import FormLabel from 'components/FormLabel/FormLabel';
import FormUpload from 'components/Upload/FormUpload/FormUpload';
import PhotoManager from 'components/PhotoManager/PhotoManager';

import { StyledHexColorPicker, StyledHexColorInput } from './MainInfoSection.styles';

// const DEFAULT_TERMS_OF_SERVICE = '';
// const DEFAULT_PRIVACY_POLICY = '';
// const DEFAULT_REFUND_POLICY = '';

const MainInfoSection = ({
  t,
  form,
  storeId,
  micrositeSettings,
  themeColor,
  logoRef,
  bannerRef,
  isBasicLayoutView,
  isSubmitting,
  setThemeColor,
  onSave
}) => {
  const [currentLogo, setCurrentLogo] = useState([]);
  const [currentBanner, setCurrentBanner] = useState([]);

  const shouldUsePhotoManagerForBanner = !micrositeSettings?.banner;

  useEffect(() => {
    if (!currentLogo.length && micrositeSettings?.logo) {
      setCurrentLogo([micrositeSettings.logo]);
    }
    if (!currentBanner.length && micrositeSettings?.bannerV2) {
      setCurrentBanner([micrositeSettings.bannerV2]);
    }
  }, [currentLogo, currentBanner, micrositeSettings]);

  // const handleOnUseDefaultTermsChange = e => {
  //   if (e.target.checked) {
  //     form.setFieldsValue({ termsOfService: DEFAULT_TERMS_OF_SERVICE });
  //   }
  // };

  // const handleOnUseDefaultPrivacyPolicyChange = e => {
  //   if (e.target.checked) {
  //     form.setFieldsValue({ privacyPolicy: DEFAULT_PRIVACY_POLICY });
  //   }
  // };

  // const handleOnUseDefaultRefundPolicyChange = e => {
  //   if (e.target.checked) {
  //     form.setFieldsValue({ refundPolicy: DEFAULT_REFUND_POLICY });
  //   }
  // };

  return (
    <>
      <Row gutter={32}>
        {!isBasicLayoutView && (
          <Col span={24} sm={12} md={6}>
            <FormLabel children={t('pageMicrositeSettings:form-field-label-theme-color')} />
            <StyledHexColorPicker color={themeColor} onChange={setThemeColor} />
            <StyledHexColorInput prefixed color={themeColor} onChange={setThemeColor} />
          </Col>
        )}
        <Col span={24} sm={12} md={isBasicLayoutView ? 12 : 6}>
          <div style={{ marginBottom: '24px' }}>
            <PhotoManager
              value={currentLogo}
              onChange={setCurrentLogo}
              label={t('pageMicrositeSettings:form-field-label-logo')}
              buttonLabel={t('pageMicrositeSettings:form-field-label-upload-button')}
              fileSizeThresholdToCompressInKb={200}
              cropAspect={1 / 1}
              ref={logoRef}
              shouldRemoveDocsMarkedForDelete
              storeId={storeId}
              isHorizontalAlign
            />
          </div>
          {shouldUsePhotoManagerForBanner ? (
            <PhotoManager
              value={currentBanner}
              onChange={setCurrentBanner}
              label={t('pageMicrositeSettings:form-field-label-banner')}
              buttonLabel={t('pageMicrositeSettings:form-field-label-upload-button')}
              fileSizeThresholdToCompressInKb={200}
              cropAspect={16 / 9}
              ref={bannerRef}
              shouldRemoveDocsMarkedForDelete
              storeId={storeId}
              isHorizontalAlign
            />
          ) : (
            <FormUpload
              name="banner"
              label={t('pageMicrositeSettings:form-field-label-banner')}
              buttonLabel={t('pageMicrositeSettings:form-field-label-upload-button')}
              acceptTypes={['image/*']}
              fileSizeLimitInMb={10}
              maxCount={1}
              isDisplayCard
              allowCropping
              cropAspect={320 / 100}
            />
          )}
        </Col>
        <Col span={24} sm={12}>
          <FormInput name="displayName" label={t('pageMicrositeSettings:form-field-label-display-name')} />
          <FormInput
            name="displayMessage"
            label={t('pageMicrositeSettings:form-field-label-display-message')}
            type="textArea"
            rows={5}
            extraProps={{ maxLength: 2000, showCount: true }}
          />
          <FormInput
            name="descriptions"
            label={t('pageMicrositeSettings:form-field-label-description')}
            type="textArea"
            rows={5}
            extraProps={{ maxLength: 2000, showCount: true }}
          />
        </Col>
        <Col span={24} sm={12} md={8}>
          <FormInput name={['socialMediaLink', 'fb']} label={t('pageMicrositeSettings:form-field-label-social-fb')} />
        </Col>
        <Col span={24} sm={12} md={8}>
          <FormInput name={['socialMediaLink', 'ig']} label={t('pageMicrositeSettings:form-field-label-social-ig')} />
        </Col>
        <Col span={24} sm={12} md={8}>
          <FormInput name={['socialMediaLink', 'wa']} label={t('pageMicrositeSettings:form-field-label-social-wa')} />
        </Col>
        <Col span={24} sm={12} md={8}>
          <FormInput name={['socialMediaLink', 'map']} label={t('pageMicrositeSettings:form-field-label-social-map')} />
        </Col>
        <Col span={24} sm={12} md={8}>
          <FormInput name={['socialMediaLink', 'tt']} label={t('pageMicrositeSettings:form-field-label-social-tt')} />
        </Col>
        <Col span={24} sm={12} md={8}>
          <FormInput name={['socialMediaLink', 'tg']} label={t('pageMicrositeSettings:form-field-label-social-tg')} />
        </Col>
        <Col span={24} sm={12}>
          <FormLabel children={t('pageMicrositeSettings:form-field-label-terms-of-service')} />
          {/* Temporary disable as we need sensible default */}
          {/* <Checkbox style={{ marginBottom: '8px' }} onChange={handleOnUseDefaultTermsChange}>
            {t('pageMicrositeSettings:form-field-label-default-template-checkbox')}
          </Checkbox> */}
          <FormInput name="termsOfService" type="textArea" rows={5} extraProps={{ maxLength: 5000, showCount: true }} />
        </Col>
        <Col span={24} sm={12}>
          <FormLabel children={t('pageMicrositeSettings:form-field-label-privacy-policy')} />
          {/* Temporary disable as we need sensible default */}
          {/* <Checkbox style={{ marginBottom: '8px' }} onChange={handleOnUseDefaultPrivacyPolicyChange}>
            {t('pageMicrositeSettings:form-field-label-default-template-checkbox')}
          </Checkbox> */}
          <FormInput name="privacyPolicy" type="textArea" rows={5} extraProps={{ maxLength: 5000, showCount: true }} />
        </Col>
        <Col span={24} sm={12}>
          <FormLabel children={t('pageMicrositeSettings:form-field-label-refund-policy')} />
          {/* Temporary disable as we need sensible default */}
          {/* <Checkbox style={{ marginBottom: '8px' }} onChange={handleOnUseDefaultRefundPolicyChange}>
            {t('pageMicrositeSettings:form-field-label-default-template-checkbox')}
          </Checkbox> */}
          <FormInput name="refundPolicy" type="textArea" rows={5} extraProps={{ maxLength: 5000, showCount: true }} />
        </Col>
        <Col span={0} sm={12}>
          {/* Hide this temporary, will enable soon */}
          {/* <FormInput name="greetingNote" label={t('pageMicrositeSettings:form-field-label-greeting-note')} type="textArea" /> */}
        </Col>
        {!isBasicLayoutView && (
          <Col span={24}>
            <Button
              type="primary"
              loading={isSubmitting}
              onClick={e => {
                onSave(e, 'mainInfo');
              }}
            >
              {t('common:form-submit-create-button-text')}
            </Button>
          </Col>
        )}
      </Row>
    </>
  );
};

export default MainInfoSection;
