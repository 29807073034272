import { useMemo, useState } from 'react';
import { Col, Descriptions, List, message, Row, Tag, Tooltip, Typography } from 'antd';
import { CopyOutlined, PrinterOutlined, InfoCircleFilled, GiftOutlined, MailOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { postSendOrderCheckoutLinkViaEmail } from 'apis/order';

import Files from 'components/Files/Files';
import FilesV2 from 'components/Files/FilesV2';
import TextButton from 'components/TextButton/TextButton';
import PriceDisplay from 'components/PriceDisplay/PriceDisplay';
import CurrencyDisplay from 'components/CurrencyDisplay/CurrencyDisplay';
import ImagePreview from 'components/ImagePreview/ImagePreview';
import FBModal, { useFBModal } from 'components/FBModal/FBModal';
import CustomerRemarksModal, { useCustomerRemarksModal } from 'components/CustomerRemarksModal/CustomerRemarksModal';
import CustomerTier from 'components/CustomerTier/CustomerTier';

import ShippingFeeIcon from 'components/Icon/ShippingFeeIcon';
import BulkPurchaseIcon from 'components/Icon/BulkPurchaseIcon';

import { getOrderExtCheckoutLink } from 'utils/constants';
import { checkIsArrayEmpty, getDistinctArray, getLabelOfConstant, guard } from 'utils/general';
import { getOrderDisplayPrintRoute } from 'utils/routes';
import { formatToDateTimeWithPMAM } from 'utils/date';
import { getFormattedContact } from 'utils/general';

import {
  FinalListItem,
  ListItem,
  PricingContentContainer,
  ShipmentRemarks,
  SubListItem,
  OrderProductPurchaseDateContainer,
  RemarksClickableIcon,
  WAClickableIcon,
  FBClickableIcon
} from './OrderDisplay.styles';

const { Item: DescItem } = Descriptions;
const { Text } = Typography;

const {
  Item: { Meta: ListItemMeta }
} = List;

const constructPriceDescription = (extraDescription, showDescAsTooltip) => {
  return showDescAsTooltip ? (
    <Tooltip title={extraDescription}>
      <InfoCircleFilled style={{ marginLeft: '4px' }} />
    </Tooltip>
  ) : (
    <span style={{ marginLeft: '4px', fontStyle: 'italic' }}>
      (
      <Text style={{ maxWidth: 180 }} ellipsis={true}>
        {extraDescription}
      </Text>
      )
    </span>
  );
};

const getAddressText = ({ addressLine1, addressLine2, city, zipcode, state, country }, countries) =>
  `${addressLine1 ? addressLine1 + ' ' : ''}${addressLine2 ? addressLine2 + ', ' : ''}${zipcode || ''} ${city ? city + ', ' : ''}${
    state ? state + ', ' : ''
  }${country ? getLabelOfConstant(country, countries) + '.' : ''}`;

const getBuyerText = ({ name, contact, sourceUserName, source }) =>
  `${name || ''}  ${source && sourceUserName ? `| ${source.toUpperCase()}:${sourceUserName}` : ''} ${
    contact && contact.countryCode ? ' (' + getFormattedContact(contact) + ')' : ''
  }`;

const formatPrintingOrderProductTitle = ({ t, orderProduct, index, productTypesConst }) => {
  const orderProductKeywordAndSkuLabel = `${orderProduct.matchingKeyword}${orderProduct.sku ? ` | ${orderProduct.sku}` : ''}`;
  const previewImageUrl = orderProduct.coverPhoto?.original?.url || orderProduct.coverPhotoFile?.url;
  const thumbnailImageUrl = orderProduct.coverPhoto?.thumbnail?.url || previewImageUrl;

  return orderProduct.coverPhoto || orderProduct.coverPhotoFile ? (
    <Row align="middle" gutter={4}>
      <Col>
        <span>{`${Number(index) + 1}. `}</span>
      </Col>
      <Col flex="64px" style={{ textAlign: 'center' }}>
        <ImagePreview width={64} previewImageUrl={previewImageUrl} thumbnailImageUrl={thumbnailImageUrl} />
      </Col>
      <Col style={{ display: 'flex' }} flex="auto">
        <span>{`[${orderProductKeywordAndSkuLabel}] - ${orderProduct.label}`}</span>
        <ShowShippingIndicator t={t} orderProduct={orderProduct} />
        <ShowBulkPurchaseIndicator t={t} orderProduct={orderProduct} />
        <ShowGiftIndicator t={t} orderProduct={orderProduct} productTypesConst={productTypesConst} />
      </Col>
    </Row>
  ) : (
    <div style={{ display: 'flex' }}>
      {`${Number(index) + 1}. [${orderProductKeywordAndSkuLabel}] - ${orderProduct.label}`}
      <ShowShippingIndicator t={t} orderProduct={orderProduct} />
      <ShowBulkPurchaseIndicator t={t} orderProduct={orderProduct} />
      <ShowGiftIndicator t={t} orderProduct={orderProduct} productTypesConst={productTypesConst} />
    </div>
  );
};

const getConsolidatedAndFormattedReceiptFiles = paymentsWithReceipts => {
  let files = [];
  paymentsWithReceipts.forEach(payment => {
    files = files.concat(payment.receiptFiles);
  });

  return files;
};

const getConsolidatedAndFormattedReceipts = paymentsWithReceipts => {
  let files = [];
  paymentsWithReceipts.forEach(payment => {
    files = files.concat(payment.receipts);
  });

  return files;
};

const PriceBreakdownListItem = ({ itemKey, label, amount, isDeduct = false, extraDescription, showDescAsTooltip }) => {
  return (
    <SubListItem key={itemKey}>
      <ListItemMeta
        title={
          <Row justify="end" align="middle">
            <div>
              <CurrencyDisplay prefix={label} />
              {extraDescription && constructPriceDescription(extraDescription, showDescAsTooltip)}
            </div>
          </Row>
        }
      />
      <PricingContentContainer>
        <PriceDisplay amount={amount} isDeduct={isDeduct} isHideSymbol />
      </PricingContentContainer>
    </SubListItem>
  );
};

const PriceHighlightListItem = ({ itemKey, label, amount }) => {
  return (
    <FinalListItem key={itemKey}>
      <ListItemMeta
        title={
          <Row justify="end">
            <CurrencyDisplay prefix={label} />
          </Row>
        }
      />
      <PricingContentContainer>
        <PriceDisplay amount={amount} />
      </PricingContentContainer>
    </FinalListItem>
  );
};

const OrderProductDisplayInfo = ({ t, matchingKeyword, sku }) => {
  return (
    <div>
      <span>
        {t('pageOrderDetails:order-display-desc-label-order-info-product-keyword')}: {matchingKeyword}
      </span>
      {sku && (
        <span>
          {' '}
          | {t('pageOrderDetails:order-display-desc-label-order-info-product-sku')}: {sku}
        </span>
      )}
    </div>
  );
};

const OrderProductPurchaseDate = ({ purchasedDate }) => {
  return (
    <OrderProductPurchaseDateContainer>
      <span>{formatToDateTimeWithPMAM(purchasedDate)}</span>
    </OrderProductPurchaseDateContainer>
  );
};

const ShowShippingIndicator = ({ t, orderProduct }) => {
  const hasShippingFeeTier = guard(() => !!orderProduct.priceDetails.shippingFeeTier, false);

  return hasShippingFeeTier ? (
    <Tooltip title={t('pageOrderDetails:order-display-desc-label-shipping-fee-icon')}>
      <div>
        <ShippingFeeIcon width={20} height={20} style={{ marginLeft: '4px', marginBottom: '2px' }} />
      </div>
    </Tooltip>
  ) : null;
};

const ShowBulkPurchaseIndicator = ({ t, orderProduct }) => {
  const hasBulkPurchaseTier = guard(() => !!orderProduct.priceDetails.bulkPurchaseTier, false);

  return hasBulkPurchaseTier ? (
    <Tooltip title={t('pageOrderDetails:order-display-desc-label-bulkpurchase-icon')}>
      <div>
        <BulkPurchaseIcon width={16} height={16} style={{ marginLeft: '4px', marginBottom: '4px' }} />
      </div>
    </Tooltip>
  ) : null;
};

const ShowGiftIndicator = ({ t, orderProduct, productTypesConst }) => {
  const orderProductType = guard(() => orderProduct.type, productTypesConst.NORMAL.code);

  return orderProductType === productTypesConst.GIFT.code ? (
    <Tooltip title={t('pageOrderDetails:order-display-desc-label-gift-icon')}>
      <div>
        <GiftOutlined width={16} height={16} style={{ marginLeft: '4px', marginBottom: '4px' }} />
      </div>
    </Tooltip>
  ) : null;
};

const OrderDisplay = ({
  order,
  orderStatuses,
  paymentMethods,
  countries,
  productTypesConst,
  socialMediaSourcesConst,
  size,
  storeCustomWAMessage,
  isAllowDisplayCheckoutLink = true,
  isViewProductsOnly = false,
  isAllowPrintInvoice = false,
  isOnPrintingPage = false,
  isEmailCheckoutLinkEnabled = false
}) => {
  const { t } = useTranslation(['common', 'pageOrder', 'pageOrderDetails']);
  const [isSendingCheckoutLinkViaEmail, setIsSendingCheckoutLinkViaEmail] = useState(false);
  const [isSentCheckoutLinkViaEmail, setIsSentCheckoutLinkViaEmail] = useState(false);

  const { isFBModalVisible, setIsFBModalVisible, selectedCustomerId, handleOnFBBtnClick } = useFBModal();
  const {
    isCustomerRemarksModalVisible,
    setIsCustomerRemarksModalVisible,
    selectedCustomerId: customerRemarksSelectedCustomerId,
    handleOnCustomerRemarksBtnClick
  } = useCustomerRemarksModal();

  const formattedOrder = useMemo(() => {
    const hasPaymentReceiptFiles = !checkIsArrayEmpty(order.payments) && !!order.payments.find(payment => !checkIsArrayEmpty(payment.receiptFiles));
    const formattedReceiptFiles = hasPaymentReceiptFiles
      ? getConsolidatedAndFormattedReceiptFiles(order.payments.filter(payment => !checkIsArrayEmpty(payment.receiptFiles)))
      : [];

    const hasPaymentReceipts = !checkIsArrayEmpty(order.payments) && !!order.payments.find(payment => !checkIsArrayEmpty(payment.receipts));
    const formattedReceipts = hasPaymentReceipts
      ? getConsolidatedAndFormattedReceipts(order.payments.filter(payment => !checkIsArrayEmpty(payment.receipts)))
      : [];

    return {
      ...order,
      payment: undefined,
      hasPaymentReceiptFiles,
      formattedReceiptFiles,
      hasPaymentReceipts,
      formattedReceipts,
      statusLabel: getLabelOfConstant(order.status, orderStatuses),
      ...(!checkIsArrayEmpty(order.payments) && {
        payment: getDistinctArray(order.payments.map(payment => getLabelOfConstant(payment.method, paymentMethods))).join(', ')
      }),
      totalDiscountAmount: guard(() => order.discount.amount, 0) + guard(() => order.promotionDetails.discountAmount, 0),
      ...(order.promotionDetails && {
        promotionDetails: order.promotionDetails
      })
    };
  }, [order, orderStatuses, paymentMethods]);

  const handleOnCopyCheckoutLinkButtonClick = () => {
    const { orderNumber, accessCode } = formattedOrder;
    const link = getOrderExtCheckoutLink(orderNumber, accessCode);
    navigator.clipboard.writeText(link);
    message.success(t('pageOrder:copy-success-message'));
  };

  const handleOnSendCheckoutLinkViaEmailButtonClick = () => {
    setIsSendingCheckoutLinkViaEmail(true);
    postSendOrderCheckoutLinkViaEmail(order._id)
      .then(() => {
        setIsSentCheckoutLinkViaEmail(true);
        message.success(t('pageOrderDetails:send-checkout-link-via-email-success-message'));
      })
      .catch(ex => {
        message.error(t('pageOrderDetails:send-checkout-link-via-email-error-message'));
      })
      .finally(() => {
        setIsSendingCheckoutLinkViaEmail(false);
      });
  };

  const totalItemCount = formattedOrder.products.reduce((totalItemCount, product) => {
    return totalItemCount + product.quantity;
  }, 0);

  const handleOnWABtnClick = contactNumber => {
    const hasPresetCustomWAMessage = !!storeCustomWAMessage;
    const message = encodeURIComponent(hasPresetCustomWAMessage ? `${storeCustomWAMessage}` : `Hi!`);
    window.open(`https://api.whatsapp.com/send?phone=${contactNumber}&text=${message}`);
    window.dataLayer.push({
      event: 'wa_event',
      action: 'wa_button_clicked'
    });
  };

  const hasPromotions = guard(() => formattedOrder.promotionDetails.promotions.length > 0, false);
  const promotionCodesDisplay = hasPromotions ? formattedOrder.promotionDetails.promotions.map(promotion => promotion.code).join(', ') : undefined;

  const customerContactNumber = formattedOrder.customer && getFormattedContact(formattedOrder.customer.contact);
  const isFBCustomer = formattedOrder.customer && formattedOrder.customer.source === guard(() => socialMediaSourcesConst.FB.code, '');
  const hasCustomerRemarks = formattedOrder.customer && formattedOrder.customer.remarks;
  const hasCustomerTier = formattedOrder.customer && formattedOrder.customer.tier;

  return (
    <div>
      {!isOnPrintingPage && (
        <>
          <Descriptions bordered size={size || 'medium'} column={{ xxl: 3, xl: 3, lg: 3, md: 1, sm: 1, xs: 1 }}>
            <DescItem label={t('pageOrderDetails:order-display-desc-label-order-number')} span={isViewProductsOnly ? 1 : 3}>
              <>
                {formattedOrder.orderNumber ? (
                  <span>{formattedOrder.orderNumber}</span>
                ) : (
                  <i>{t('pageOrderDetails:order-display-desc-label-order-number-empty')}</i>
                )}
                {isAllowDisplayCheckoutLink && formattedOrder.accessCode && (
                  <TextButton
                    icon={<CopyOutlined />}
                    onClick={handleOnCopyCheckoutLinkButtonClick}
                    text={t('pageOrder:table-action-button-copy-checkout-link')}
                  />
                )}
                {isAllowPrintInvoice && formattedOrder.accessCode && (
                  <>
                    <TextButton
                      icon={<PrinterOutlined />}
                      onClick={() => window.open(getOrderDisplayPrintRoute(formattedOrder._id).path)}
                      text={t('pageOrderDetails:pdf-invoice-print')}
                    />
                    <TextButton
                      icon={<PrinterOutlined />}
                      onClick={() =>
                        window.open(
                          getOrderDisplayPrintRoute(formattedOrder._id, {
                            compact: true
                          }).path
                        )
                      }
                      text={t('pageOrderDetails:pdf-invoice-print-compact')}
                    />
                  </>
                )}
                {isEmailCheckoutLinkEnabled && isAllowDisplayCheckoutLink && formattedOrder.accessCode && (
                  <TextButton
                    icon={<MailOutlined />}
                    onClick={handleOnSendCheckoutLinkViaEmailButtonClick}
                    text={t('pageOrderDetails:send-checkout-link-via-email-button')}
                    disabled={isSentCheckoutLinkViaEmail}
                    loading={isSendingCheckoutLinkViaEmail}
                  />
                )}
              </>
            </DescItem>

            {!isViewProductsOnly && (
              <DescItem label={t('pageOrderDetails:order-display-desc-label-status')} span={1}>
                {formattedOrder.statusLabel}
              </DescItem>
            )}

            <DescItem label={t('pageOrderDetails:order-display-desc-label-purchase-date')} span={2}>
              {formattedOrder.createdAt ? formatToDateTimeWithPMAM(formattedOrder.createdAt) : '-'}
            </DescItem>

            <DescItem label={t('pageOrderDetails:order-display-desc-label-buyer')} span={1}>
              {hasCustomerTier && <CustomerTier emoji={formattedOrder.customer.tier.emoji} label={formattedOrder.customer.tier.label} />}
              {formattedOrder.customer && getBuyerText(formattedOrder.customer)}{' '}
              {
                <Tooltip>
                  {hasCustomerRemarks && <RemarksClickableIcon onClick={() => handleOnCustomerRemarksBtnClick(formattedOrder.customer._id)} />}
                  {!!customerContactNumber && <WAClickableIcon onClick={() => handleOnWABtnClick(customerContactNumber)} />}
                  {isFBCustomer && <FBClickableIcon width={14} height={14} onClick={() => handleOnFBBtnClick(formattedOrder.customer._id)} />}
                </Tooltip>
              }
            </DescItem>

            {!isViewProductsOnly && (
              <DescItem label={t('pageOrderDetails:order-display-desc-label-payment')} span={1}>
                {formattedOrder.payment || <i>{t('pageOrderDetails:order-display-desc-label-payment-empty')}</i>}
              </DescItem>
            )}

            {!isViewProductsOnly && (
              <DescItem label={t('pageOrderDetails:order-display-desc-label-shipping-info')} span={1}>
                <div>
                  {(formattedOrder.shipment && formattedOrder.shipment.label && (
                    <p
                      style={{
                        marginBottom: formattedOrder.shipment.remarks ? '14px' : '0'
                      }}
                    >
                      {formattedOrder.shipment.label}
                    </p>
                  )) || <i>{t('pageOrderDetails:order-display-desc-label-shipping-info-empty')}</i>}
                  {<ShipmentRemarks>{formattedOrder.shipment && formattedOrder.shipment.remarks}</ShipmentRemarks>}
                </div>
              </DescItem>
            )}

            <DescItem label={t('pageOrderDetails:order-display-desc-label-recipient-info')} span={isViewProductsOnly ? 2 : 3}>
              <p>
                {t('pageOrderDetails:order-display-desc-label-recipient-info-name')}:{' '}
                {(formattedOrder.shipment && formattedOrder.shipment.recipientName) || (
                  <i>{t('pageOrderDetails:order-display-desc-label-recipient-info-name-empty')}</i>
                )}
              </p>
              <p>
                {t('pageOrderDetails:order-display-desc-label-recipient-info-contact')}:{' '}
                {(formattedOrder.shipment &&
                  formattedOrder.shipment.recipientContact &&
                  getFormattedContact(formattedOrder.shipment.recipientContact)) || (
                  <i>{t('pageOrderDetails:order-display-desc-label-recipient-info-contact-empty')}</i>
                )}
              </p>
              <p>
                {t('pageOrderDetails:order-display-desc-label-recipient-info-address')}:{' '}
                {(formattedOrder.shipment && formattedOrder.shipment.address && getAddressText(formattedOrder.shipment.address, countries)) || (
                  <i>{t('pageOrderDetails:order-display-desc-label-recipient-info-address-empty')}</i>
                )}
              </p>
            </DescItem>

            {!isViewProductsOnly && (
              <DescItem label={t('pageOrderDetails:order-display-desc-label-remarks')} span={3}>
                <p
                  style={{
                    marginBottom: formattedOrder.tags?.length > 0 ? '12px' : '0'
                  }}
                >
                  {formattedOrder.remarks || <i>{t('pageOrderDetails:order-display-desc-label-remarks-empty')}</i>}
                </p>
                {formattedOrder.tags?.map(tag => {
                  return <Tag>{tag.title}</Tag>;
                })}
              </DescItem>
            )}

            {!isViewProductsOnly && (formattedOrder.hasPaymentReceiptFiles || formattedOrder.hasPaymentReceipts) && (
              <DescItem label={t('pageOrderDetails:order-display-desc-label-receipts')} span={3}>
                {formattedOrder.hasPaymentReceiptFiles ? <Files files={formattedOrder.formattedReceiptFiles} photoSize={80} /> : null}
                {formattedOrder.hasPaymentReceipts ? <FilesV2 files={formattedOrder.formattedReceipts} photoSize={80} /> : null}
              </DescItem>
            )}
          </Descriptions>
          <br />
        </>
      )}
      <Descriptions layout="vertical" bordered size="medium">
        <DescItem label={t('pageOrderDetails:order-display-desc-label-order-info')}>
          <List>
            {formattedOrder.products &&
              formattedOrder.products.map((orderProduct, index) => {
                const previewImageUrl = orderProduct.coverPhoto?.original?.url || orderProduct.coverPhotoFile?.url;
                const thumbnailImageUrl = orderProduct.coverPhoto?.thumbnail?.url || previewImageUrl;

                return (
                  <ListItem key={`${orderProduct.productId}_${orderProduct.varianceId}`}>
                    <ListItemMeta
                      title={
                        isOnPrintingPage ? (
                          formatPrintingOrderProductTitle({ t, orderProduct, index, productTypesConst })
                        ) : (
                          <Row align="middle" gutter={6}>
                            {(orderProduct.coverPhoto?.thumbnail?.url || orderProduct.coverPhotoFile?.url) && (
                              <Col flex="64px">
                                <ImagePreview
                                  width={64}
                                  previewImageUrl={previewImageUrl}
                                  thumbnailImageUrl={thumbnailImageUrl}
                                  style={{ display: 'inline-block' }}
                                />
                              </Col>
                            )}
                            <Col flex="auto" style={{ display: 'flex' }}>
                              {orderProduct.label}
                              <ShowShippingIndicator t={t} orderProduct={orderProduct} />
                              <ShowBulkPurchaseIndicator t={t} orderProduct={orderProduct} />
                              <ShowGiftIndicator t={t} orderProduct={orderProduct} productTypesConst={productTypesConst} />
                            </Col>
                          </Row>
                        )
                      }
                      description={
                        !isOnPrintingPage ? (
                          <div>
                            <OrderProductDisplayInfo t={t} matchingKeyword={orderProduct.matchingKeyword} sku={orderProduct.sku} />
                            {orderProduct.purchasedDate && <OrderProductPurchaseDate purchasedDate={orderProduct.purchasedDate} />}
                          </div>
                        ) : (
                          orderProduct.purchasedDate && <OrderProductPurchaseDate purchasedDate={orderProduct.purchasedDate} />
                        )
                      }
                    />
                    <Row style={{ width: '50%', justifyContent: 'space-between' }}>
                      <span style={{ marginLeft: '20%' }}>
                        <PriceDisplay prefix={`${orderProduct.quantity} x `} amount={orderProduct.priceDetails.averagePrice} isHideSymbol />
                      </span>
                      <PriceDisplay amount={orderProduct.priceDetails.totalAmount} isHideSymbol />
                    </Row>
                  </ListItem>
                );
              })}
            <PriceBreakdownListItem
              itemKey="subtotal"
              label={t('pageOrderDetails:order-display-desc-label-subtotal')}
              amount={formattedOrder.subtotalPrice}
            />
            <PriceBreakdownListItem
              itemKey="shippingFee"
              label={t('pageOrderDetails:order-display-desc-label-shipping-fee')}
              amount={formattedOrder.shipment && formattedOrder.shipment.shippingFee}
            />
            {formattedOrder.shipment && !!formattedOrder.shipment.productShippingFee && (
              <PriceBreakdownListItem
                itemKey="shippingFee"
                label={t('pageOrderDetails:order-display-desc-label-product-shipping-fee')}
                amount={formattedOrder.shipment.productShippingFee}
              />
            )}

            {formattedOrder.extraFee && formattedOrder.extraFee.amount ? (
              <PriceBreakdownListItem
                itemKey="extraFee"
                label={t('pageOrderDetails:order-display-desc-label-extra-fee')}
                amount={formattedOrder.extraFee.amount}
                extraDescription={formattedOrder.extraFee.remarks}
                showDescAsTooltip={!isOnPrintingPage}
              />
            ) : null}
            {formattedOrder.discount && formattedOrder.discount.amount ? (
              <PriceBreakdownListItem
                itemKey="discount"
                label={t('pageOrderDetails:order-display-desc-label-discount')}
                amount={formattedOrder.discount.amount}
                extraDescription={formattedOrder.discount.remarks}
                showDescAsTooltip={!isOnPrintingPage}
                isDeduct
              />
            ) : null}
            {hasPromotions ? (
              <PriceBreakdownListItem
                itemKey="voucherDiscount"
                label={
                  isOnPrintingPage
                    ? `${t('pageOrderDetails:order-display-desc-label-promo')} (${promotionCodesDisplay})`
                    : `${t('pageOrderDetails:order-display-desc-label-promo')}`
                }
                extraDescription={!isOnPrintingPage ? promotionCodesDisplay : undefined}
                amount={formattedOrder.promotionDetails.discountAmount}
                showDescAsTooltip={!isOnPrintingPage}
                isDeduct
              />
            ) : null}
            {formattedOrder.point && formattedOrder.point.redeem && formattedOrder.point.redeem.discountAmount ? (
              <PriceBreakdownListItem
                itemKey="pointDiscount"
                label={
                  isOnPrintingPage
                    ? `${t('pageOrderDetails:order-display-desc-label-points-redeemed')} (${t(
                        'pageOrderDetails:order-display-desc-tooltip-point-redeemed-message',
                        {
                          point: formattedOrder.point.redeem.amount
                        }
                      )})`
                    : `${t('pageOrderDetails:order-display-desc-label-points-redeemed')}`
                }
                extraDescription={
                  !isOnPrintingPage
                    ? t('pageOrderDetails:order-display-desc-tooltip-point-redeemed-message', {
                        point: formattedOrder.point.redeem.amount
                      })
                    : undefined
                }
                amount={formattedOrder.point.redeem.discountAmount}
                showDescAsTooltip={!isOnPrintingPage}
                isDeduct
              />
            ) : null}
            <PriceHighlightListItem
              itemKey="total"
              label={t('pageOrderDetails:order-display-desc-label-total')}
              amount={formattedOrder.totalAmount}
            />
            <PriceHighlightListItem
              itemKey="balanceAmount"
              label={t('pageOrderDetails:order-display-desc-label-balance')}
              amount={formattedOrder.balanceAmount}
            />
          </List>
          <span style={{ display: 'block', marginTop: '8px' }}>
            {t('pageOrderDetails:order-display-desc-label-total-item-count', {
              itemCount: totalItemCount
            })}
          </span>
          {!isOnPrintingPage && (
            <span style={{ display: 'block', marginTop: '8px' }}>
              {t('pageOrderDetails:order-display-desc-label-total-weight', {
                totalWeightInKG: formattedOrder.shipment.totalWeightInKG
              })}
            </span>
          )}
          {!isOnPrintingPage && formattedOrder.point?.earn?.amount && (
            <span style={{ display: 'block', marginTop: '8px' }}>
              {t('pageOrderDetails:order-display-desc-label-earn-points', {
                points: formattedOrder.point.earn.amount
              })}
            </span>
          )}
        </DescItem>
      </Descriptions>

      {isFBModalVisible && (
        <FBModal
          isFBModalVisible={isFBModalVisible}
          setIsFBModalVisible={setIsFBModalVisible}
          selectedCustomerId={selectedCustomerId}
          storeCustomWAMessage={storeCustomWAMessage}
        />
      )}
      {isCustomerRemarksModalVisible && (
        <CustomerRemarksModal
          isCustomerRemarksModalVisible={isCustomerRemarksModalVisible}
          setIsCustomerRemarksModalVisible={setIsCustomerRemarksModalVisible}
          selectedCustomerId={customerRemarksSelectedCustomerId}
        />
      )}
    </div>
  );
};

export default OrderDisplay;
