import { StyledTag } from './CustomerTier.styles';

const CustomerTier = ({ emoji, label, style, className }) => {
  return (
    <StyledTag color="#f1f5fc" className={className} style={{ ...style, color: 'black' }}>
      {emoji} {label}
    </StyledTag>
  );
};

export default CustomerTier;
